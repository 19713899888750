import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Pagination,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import BTable from "react-bootstrap/Table";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { GlobalFilter } from "./GlobalFilter";

import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import { Link, NavLink } from "react-router-dom";
import moment from "moment/moment";
import TopHeader from "../../../component/TopHeader";

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    globalFilter,
    setGlobalFilter,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <Row className="mt-4">
        <Col className="d-flex align-items-center">
          Show
          <select
            className="form-control w-auto mx-2"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
          entries
        </Col>
        <Col className="d-flex justify-content-end">
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
        </Col>
      </Row>
      <BTable className="mt-3" hover responsive {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="feather icon-arrow-down text-muted float-right" />
                      ) : (
                        <span className="feather icon-arrow-up text-muted float-right" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
      <Row className="justify-content-between">
        <Col>
          <span className="d-flex align-items-center">
            Page{" "}
            <strong>
              {" "}
              {pageIndex + 1} of {pageOptions.length}{" "}
            </strong>{" "}
            | Go to page:{" "}
            <input
              type="number"
              className="form-control ml-2"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "100px" }}
            />
          </span>
        </Col>
        <Col>
          <Pagination className="justify-content-end">
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          </Pagination>
        </Col>
      </Row>
    </>
  );
}
const ReferEarn = () => {
  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        Cell: ({ value, row }) => {
          return <p>{row.index + 1}</p>;
        },
      },

      {
        Header: "Refferer",
        accessor: "reffer",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "User Account",
        accessor: "type",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      // {
      //   Header: "Refered Date",
      //   accessor: "creed_at",
      //   Cell: ({ value, row }) => {
      //     return <p>{""}</p>;
      //   },
      // },

      {
        Header: "Reffer Person",
        accessor: "name",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },

      {
        Header: "Installed Date",
        accessor: "created_at",
        Cell: ({ value, row }) => {
          return <p>{moment(value).format("DD/MM/YYYY")}</p>;
        },
      },
      {
        Header: "Registered Account",
        accessor: "register_type",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
    ],
    []
  );

  const [list, setList] = useState([]);
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  const startDate = (e) => {
    setFrom(e.target.value);
    setTo("");
  };

  const ToDate = (e) => {
    setTo(e.target.value);
  };

  const getList = () => {
    let params = `?start_date=${from}&end_date=${to}`;
    if (from && to) {
    } else if (!from && !to) {
    }
  };

  useEffect(() => {
    getList();
  }, [from, to]);
  return (
    <>
      <aside>
        <TopHeader type="referEarn" />
        <article className="p-3">
          <div className="p-2 bg-white rounded-2">
            <div className="ad-btn w-100 flex-ae-jb px-0 py-2">
              <Col md={3} xs={6} className="px-2 my-2">
                <span>From Date</span>
                <input
                  type="date"
                  className="form-control"
                  onChange={startDate}
                  value={from}
                />
              </Col>
              <Col md={3} xs={6} className="px-2 my-2">
                <span>To Date</span>
                <input
                  type="date"
                  className="form-control"
                  onChange={ToDate}
                  value={to}
                />
              </Col>
            </div>
            <Table columns={columns} data={list} className="" />
          </div>
        </article>
      </aside>
    </>
  );
};

export default ReferEarn;
