import React from "react";

function Input({ type, id, name, placeholder, change, errText, err }) {
  return (
    <>
      <input
        type={type}
        id={id}
        name={name}
        placeholder={placeholder}
        onChange={change}
        className="px-2 mt-1"
      />
      {errText && err == false && <p className="err">{errText}</p>}
    </>
  );
}

export default Input;
