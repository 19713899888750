import React, { useRef } from "react";
import { useState, useEffect } from "react";
import TopHeader from "../../../component/TopHeader";
import autoPng from "../../../assets/img/auto.jpg";
import {
  Row,
  Col,
  Card,
  Pagination,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Map from "./map";
import MapPage from "./map";
import Home from "./home";
import DistanceMap from "./distanceMap";
import Select from "react-select";
import Timer from "./timer";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

function AddOfflineBooking() {
  const navigate = useNavigate();
  const location = useLocation();
  const type = location?.state?.type;
  const status = location?.state?.status;

  // console.log(type);
  // console.log(status);

  const [locationData, setLocationData] = useState();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [mobile, setMobile] = useState();
  const [fcmId, setFcmId] = useState();
  const [primaryNo, setPrimaryNo] = useState();
  const [bookingStatus, setBookingStatus] = useState(0);
  const [time, setTime] = useState();
  const [date, setDate] = useState();
  const [user, setUser] = useState();
  const [selectUser, setSelectUser] = useState();
  const [searchId, setSearchId] = useState();
  const [amount, setAmount] = useState();
  const [open, setOpen] = useState(false);
  const [opens, setOpens] = useState(false);
  const [search, setSearch] = useState(false);
  const [value, setValue] = useState(false);
  const [searchThird, setSearchThird] = useState(false);
  const [showStatus, setShowStatus] = useState();
  const [btnEnable, setBtnEnable] = useState(false);
  const [bookingType, setBookingType] = useState(0);

  //  USER LIST

  const userList = () => {};

  useEffect(() => {
    userList();
  }, []);

  const options = user?.map(({ name, id, mobile }) => {
    return {
      value: id,
      label: name + " / " + mobile,
    };
  });

  // console.log("loc", selectUser?.value);

  // console.log(locationData?.routes[0]?.legs[0]?.duration);

  const addBooking = (e) => {
    let formdata = new FormData();
    let whole_string = locationData?.routes[0]?.legs[0]?.distance?.text;
    let split_string = whole_string?.split(/(\d+.\d)/);
    formdata.append(
      "user_pickup_latitude",
      locationData?.routes[0]?.legs[0]?.start_location?.lat()
    );
    formdata.append(
      "user_pickup_longitude",
      locationData?.routes[0]?.legs[0]?.start_location?.lng()
    );
    formdata.append(
      "user_drop_latitude",
      locationData?.routes[0]?.legs[0]?.end_location?.lat()
    );
    formdata.append(
      "user_drop_longitude",
      locationData?.routes[0]?.legs[0]?.end_location?.lng()
    );
    formdata.append(
      "pickup_address",
      locationData?.routes[0]?.legs[0]?.start_address
    );
    formdata.append(
      "drop_address",
      locationData?.routes[0]?.legs[0]?.end_address
    );
    formdata.append("distance", split_string[1]);
    formdata.append("status", bookingStatus);
    formdata.append(
      "ride_duration",
      locationData?.routes[0]?.legs[0]?.duration?.text
    );

    // if (isNaN(name) || name < 1) {
    //   formdata.append("name", name);
    // } else {
    //   formdata.append("name", name);
    // }

    if (selectUser?.value == null) {
      formdata.append("name", name);
      formdata.append("email", email);
      formdata.append("mobile", mobile);
      formdata.append("fcm_id", "");
      formdata.append("primary_mobile", primaryNo);
      formdata.append("user_type", "new");
    } else {
      // console.log(selectUser?.value);
      formdata.append("user_type", "exist");
      formdata.append("user_id", selectUser?.value);
    }

    if (bookingStatus == 4) {
      let prebooking_time = [date, time];
      formdata.append("ride_type", 2);
      formdata.append("prebooking_time", prebooking_time.toString());
      setBtnEnable(true);
    } else if (bookingStatus == 0) {
      formdata.append("ride_type", 2);
      setBtnEnable(true);
    }
  };

  // console.log(searchId);

  // const searchStatus = () => {
  //   let data = {
  //     search_ride_id: searchId,
  //   };
  //   if (searchId?.length != "") {
  //     api.offlineStatus(data).then((res) => {
  //       setShowStatus(res?.search_ride_status);
  //     });
  //   }
  // };

  const [distanceKm, setDistanceKm] = useState();

  // const searchAuto = () => {
  //   let data = {};
  //   if (search == true) {
  //     data.distance = 1.5;
  //     setSearch(false);
  //   } else {
  //     data.distance = 2.5;
  //   }

  //   api.offlineSecondSearch(searchId, data).then((res) => {
  //     // console.log("res", res);
  //     setDistanceKm(res?.distance);
  //     setValue(false);
  //   });
  // };

  const tryAgainLater = () => {
    alert("Driver Not Allocated! Try Again Later");
    navigate("/offline-booking");
  };

  const searchAuto = (kms) => {
    let data = {
      distance: kms,
    };
  };

  const searchStatus = async () => {
    let data = {
      search_ride_id: searchId,
    };
  };

  // console.log(searchId);

  useEffect(() => {
    if (searchId) {
      rideAvail();
    }
  }, [searchId]);

  const rideAvail = async () => {
    console.log("rideAvail", searchId);
    setTimeout(async () => {
      let rideStatus0 = await searchStatus();
      console.log(rideStatus0, "rideStatus frm setTimeout 0");
      if (rideStatus0 == 0) {
        setTimeout(async () => {
          let rideStatus1 = await searchStatus();
          console.log(rideStatus1, "rideStatus frm setTimeout 1");
          if (rideStatus1 == 0) {
            console.log("1rd ride cancell");
            searchAuto(1.5);
            setTimeout(async () => {
              let rideStatus2 = await searchStatus();
              console.log(rideStatus2, "rideStatus frm setTimeout 2");
              if (rideStatus2 == 0) {
                searchAuto(2.5);
                setTimeout(async () => {
                  let rideStatus3 = await searchStatus();
                  console.log(rideStatus3, "rideStatus frm setTimeout 3");
                  if (rideStatus3 == 0) {
                    console.log("3nd ride cancell");
                    setSearch(false);
                    setSearchId("");

                    // let rep = {
                    //   response: {
                    //     data: {
                    //       message: "Driver is not available",
                    //     },
                    //   },
                    // };
                  } else {
                    console.log("3rd ride success");
                  }
                }, 30000);
                console.log("2nd ride cancell");
              } else {
                console.log("2nd ride success");
              }
            }, 30000);
          } else {
            console.log("1rd ride success");
          }
        }, 30000);
      } else {
        console.log("0rd ride success");
      }
    }, 10000);
  };

  return (
    <aside>
      {type === "view" ? (
        <TopHeader type="viewBooking" />
      ) : (
        <TopHeader type="addBooking" />
      )}

      <article>
        <div className="p-3">
          <div className="p-3 bg-white rounded-2">
            <div className="flex-ac-jb">
              <p
                className="permission-text txt-primary4 mb-0 mt-0 text-uppercase"
                for=""
              ></p>
              <span>Booking By: AutoKaar</span>
            </div>
            <Form className="">
              <div className="tab-tabs">
                <Tabs
                  defaultActiveKey="Detail-View"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                >
                  <Tab eventKey="Detail-View" title="Exist User">
                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="label-off" for="">
                            User Name
                          </label>
                          <div className="seac-box">
                            <Select
                              value={selectUser}
                              onChange={(e) => setSelectUser(e)}
                              options={options}
                              class="bg-transparent"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="Detail" title="New User">
                    <div className="d-flex flex-wrap">
                      <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                        <div className="form-check ps-0">
                          <div className="px-1 col-sm-12 modal-label-input">
                            <label className="label-off" for="">
                              User Name
                            </label>
                            <input
                              id="example-if-email"
                              name="username"
                              placeholder="user name"
                              onChange={(e) => setName(e.target.value)}
                              value={name}
                              className="bg-transparent"
                              type="text"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                        <div className="form-check ps-0">
                          <div className="px-1 col-sm-12 modal-label-input">
                            <label className="text-primary-dark" for="">
                              Mobile
                            </label>
                            {/* <input
                      id="example-if-email"
                      name="mobile"
                      placeholder="mobile"
                      onChange={(e) => setMobile(e.target.value)}
                      value={mobile}
                      className="bg-transparent"
                      type="number"
                    /> */}
                            <input
                              onInput={(e) => {
                                if (e.target.value.length > e.target.maxLength)
                                  e.target.value = e.target.value.slice(
                                    0,
                                    e.target.maxLength
                                  );
                                if (e.target.value.length !== 10) {
                                  e.target.setCustomValidity("invalid Number");
                                } else if (e.target.value.length == 10) {
                                  e.target.setCustomValidity("");

                                  setMobile(e.target.value);
                                }
                              }}
                              onFocus={(e) =>
                                e.target.addEventListener(
                                  "wheel",
                                  function (e) {
                                    e.preventDefault();
                                  },
                                  { passive: false }
                                )
                              }
                              maxlength={10}
                              onChange={(e) => setMobile(e.target.value)}
                              value={mobile}
                              type="number"
                              className="bg-transparent"
                              placeholder="Primary Contact"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-12 col-sm-6 ps-0">
                        <div className="form-check ps-0">
                          <div className="px-1 col-sm-12 modal-label-input">
                            <label className="text-primary-dark" for="">
                              Email
                            </label>
                            <input
                              placeholder="email"
                              name="email"
                              className="bg-transparent"
                              type="text"
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-12 col-sm-6 ps-0">
                        <div className="form-check ps-0">
                          <div className="px-1 col-sm-12 modal-label-input">
                            <label className="text-primary-dark" for="">
                              Primary Contact
                            </label>
                            {/* <input
                      placeholder="Primary Contact"
                      name="sos"
                      className="bg-transparent"
                      onChange={(e) => setPrimaryNo(e.target.value)}
                      value={primaryNo}
                      type="number"
                    /> */}
                            <input
                              onInput={(e) => {
                                if (e.target.value.length > e.target.maxLength)
                                  e.target.value = e.target.value.slice(
                                    0,
                                    e.target.maxLength
                                  );
                                if (e.target.value.length !== 10) {
                                  e.target.setCustomValidity("invalid Number");
                                } else if (e.target.value.length == 10) {
                                  e.target.setCustomValidity("");

                                  setPrimaryNo(e.target.value);
                                }
                              }}
                              onFocus={(e) =>
                                e.target.addEventListener(
                                  "wheel",
                                  function (e) {
                                    e.preventDefault();
                                  },
                                  { passive: false }
                                )
                              }
                              maxlength={10}
                              onChange={(e) => setPrimaryNo(e.target.value)}
                              value={primaryNo}
                              type="number"
                              className="bg-transparent"
                              placeholder="Primary Contact"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </div>
              {/* <div
                className="
              d-flex flex-wrap align-items-center mb-3"
              >
                <button
                  className="me-3"
                  type="button"
                  onClick={() => setBookingType(0)}
                >
                  Exist User
                </button>
                <button type="button" onClick={() => setBookingType(1)}>
                  New User
                </button>
              </div> */}

              <div className="check-color my-3">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    value="option1"
                    onClick={(e) => setBookingStatus(4)}
                    checked={bookingStatus == 4 ? true : false}
                  />
                  <label class="form-check-label" htmlFor="inlineRadio1">
                    Pre Booking
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    value="option2"
                    onClick={(e) => setBookingStatus(0)}
                    checked={bookingStatus == 0 ? true : false}
                  />
                  <label class="form-check-label" htmlFor="inlineRadio1">
                    Instant Booking
                  </label>
                </div>
              </div>

              {bookingStatus == 4 ? (
                <>
                  <div className="form-group col-12 d-sm-flex d-md-flex">
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Pre Book Date
                          </label>
                          <input
                            type="date"
                            id="example-if-email"
                            placeholder="from"
                            name="from"
                            className="bg-transparent"
                            onChange={(e) => setDate(e.target.value)}
                            value={date}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Pre Book Time
                          </label>
                          <input
                            type="time"
                            id="example-if-email"
                            placeholder="from"
                            name="from"
                            onChange={(e) => setTime(e.target.value)}
                            value={time}
                            className="bg-transparent"
                          />
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </>
              ) : null}
            </Form>
          </div>
        </div>

        <div className="p-3">
          <div className="col-12 p-3 bg-white rounded-2">
            <DistanceMap setLocationData={setLocationData} />
          </div>
        </div>
        <div className="col-xs-12 d-flex justify-content-center pr-2 mb-2">
          <button
            className="comn-btn bg-primary px-3 py-2 me-2"
            onClick={addBooking}
            disabled={
              locationData == null ? true : btnEnable == true ? true : false
            }
          >
            Submit Booking
          </button>
          {/* <button onClick={() => setOpen(true)}>open</button> */}
        </div>
      </article>
      {open && (
        <div
          className="customPopup"
          style={{ zIndex: "999999", top: 0, left: 0, bottom: 0, right: 0 }}
        >
          <div className="customPopupDesign">
            <h5 className="text-center mb-2">
              {search == true ? "Searching Auto..." : "Mr.AutoKaar"}
            </h5>
            <div className="d-flex justify-content-between">
              <div className="">
                <p className="text-primary">
                  <b>{search == true ? `Fare Estimation: Rs.${amount}` : ""}</b>
                </p>
              </div>
              <div className="">
                {search == true ? (
                  <p className="">
                    Status:{" "}
                    {showStatus == 0
                      ? "Searching"
                      : showStatus == 1
                      ? "Driver not allocate"
                      : showStatus == 2
                      ? "Driver allocated"
                      : showStatus == 3
                      ? "Canceled"
                      : showStatus == 4
                      ? "Prebooking"
                      : "Searching"}
                  </p>
                ) : (
                  <p></p>
                )}
              </div>
            </div>
            {search == true ? (
              <div className="auto-move py-4">
                <img className="auto-img" src={autoPng} />
              </div>
            ) : (
              <div className="">
                <div className="auto-move ">
                  <img className="auto-imgs" src={autoPng} />
                </div>
                <div className="d-flex justify-content-center py-3">
                  <button
                    onClick={addBooking}
                    className="comn-btn btn btn-outline-success px-3 py-2 me-2 "
                    disabled={btnEnable == true ? true : false}
                  >
                    Search Again
                  </button>
                  <button
                    onClick={(e) => {
                      setSearchId("");
                      setOpen(false);
                    }}
                    className="comn-btn btn btn-outline-warning px-3 py-2 me-2 "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <Modal show={opens} onHide={() => setOpens(false)}>
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          <Modal.Title>
            <h5 className="text-align-center">Searching Auto...</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-end">
            <p className="text-primary">
              <b>Estimated Amount: {"Rs. " + amount}</b>
            </p>
          </div>
          <div className="d-flex justify-content-end">
            <p className="">
              Status:{" "}
              {showStatus == 0
                ? "Searching"
                : showStatus == 1
                ? "Driver not allocate"
                : showStatus == 2
                ? "Driver allocated"
                : showStatus == 3
                ? "Canceled"
                : showStatus == 4
                ? "Prebooking"
                : "Searching"}
            </p>
          </div>
          {search == true ? (
            <div className="auto-move py-4">
              <img className="auto-img" src={autoPng} />
            </div>
          ) : (
            <div className="">
              <div className="auto-move ">
                <img className="auto-imgs" src={autoPng} />
              </div>
              <div className="d-flex justify-content-center py-3">
                <button
                  onClick={addBooking}
                  className="comn-btn btn btn-outline-success px-3 py-2 me-2 "
                  disabled={btnEnable == true ? true : false}
                >
                  Search Again
                </button>
                <button
                  onClick={(e) => {
                    setSearchId("");
                    setOpens(false);
                  }}
                  className="comn-btn btn btn-outline-warning px-3 py-2 me-2 "
                >
                  Cancel
                </button>
              </div>
            </div>
          )}

          {/* {value == true ? (
            <div class="d-flex justify-content-center p-3">
              <button
                onClick={distanceKm == 2.5 ? tryAgainLater : searchAuto}
                className="comn-btn bg-primary px-3 py-2 me-2 "
              >
                Search Again
              </button>
            </div>
          ) : (
            <div class="d-flex justify-content-center p-3">
              <Timer
                value={value}
                setValue={setValue}
                searchStatus={searchStatus}
              />
            </div>
          )} */}
        </Modal.Body>
      </Modal>
    </aside>
  );
}

export default AddOfflineBooking;
