import React from "react";

const ErrorPopup = ({ alertMessage, onCloseClick }) => {
  return (
    <div
      style={{
        zIndex: 1000,
        position: "fixed",
        top: 0,
        width: "100%",
        height: "100vh",
        backgroundColor: "rgb(0 0 0 / 30%)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          width: "30%",
          minHeight: "200px",
          backgroundColor: "#fff",
          borderRadius: "10px",
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h6 style={{ textAlign: "center", textTransform: "capitalize" }}>
          {alertMessage}
        </h6>
        <div
          onClick={onCloseClick}
          className="bt_sub"
          style={{
            fontSize: "13px",
            padding: "8px 20px",
            marginTop: "15px",
            background: "#d3000b",
            borderRadius: "30px",
            padding: "10px 40px",
            color: "#fff",
            fontFamily: "f3",
            border: "0",
            cursor: "pointer",
            /* marginBottom: '20px', */
            textDecoration: "none",
          }}
        >
          CLOSE
        </div>
      </div>
    </div>
  );
};

export default ErrorPopup;
