import React, { useState } from "react";
import { Button, Image } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../../../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPenAlt, faEye } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import { Link, useSearchParams } from "react-router-dom";
import { bannerList, Logo, maleAvatar, LogoIn } from "../../../assets/img";
import Pagination from "react-bootstrap/Pagination";
import { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import moment from "moment/moment";
import { BsFillPrinterFill, BsPrinter } from "react-icons/bs";

const Invoice = () => {
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [rideDReview, setRideDReview] = useState({});
  const [rideUReview, setRideUReview] = useState({});
  const [addModal, setAddModal] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const [searchParamsInd, setSearchParamsInd] = useSearchParams();

  const Id = searchParams.get("Id");
  // const index = searchParams.get("index");
  const index = searchParamsInd.get("index");

  // console.log(index);

  const getList = () => {};

  useEffect(() => {
    if (Id) {
      getList();
    }
  }, []);

  return (
    <div className="p-3   w-100 " style={{ height: "100vh" }}>
      <div className="d-flex justify-content-end pb-3">
        <button
          className="p-2 invoice-btn  d-flex align-items-center"
          onClick={() => window.print()}
        >
          <BsPrinter className="mx-1" />
        </button>
      </div>

      <div className="w-90">
        <Image src={LogoIn} className="print-logo py-3" />
        <table className="table table-bordered table-striped ">
          <tbody>
            <tr>
              <th>Invoice ID</th>
              <td>{"#00" + index}</td>
            </tr>
            <tr>
              <th>Ride ID</th>
              <td>{list?.id ? "#" + list?.id : "-"}</td>
            </tr>
            <tr>
              <th>Ride Type</th>
              <td>
                {list?.ride_type == 1
                  ? "Ride Now"
                  : list?.ride_type == 2
                  ? "Pre Booking"
                  : list?.ride_type == 3
                  ? "Offline Booking by Admin"
                  : ""}
              </td>
            </tr>
            <tr>
              <th>Driver Name</th>
              <td>{list?.driver?.name}</td>
            </tr>
            <tr>
              <th>User Name</th>
              <td>{list?.user?.name}</td>
            </tr>
            <tr>
              <th> From</th>
              <td> {list?.ride_detail?.pickup_address}</td>
            </tr>
            <tr>
              <th>To</th>
              <td> {list?.ride_detail?.drop_address}</td>
            </tr>
            <tr>
              <th>Ride Status</th>
              <td>
                {list?.status == 10
                  ? "Completed"
                  : list?.status == 12
                  ? "Completed"
                  : " "}
              </td>
            </tr>
            <tr>
              <th>Ride Cost (Including Tax)</th>
              <td>{"Rs. " + list?.final_amount}</td>
            </tr>
            <tr>
              <th>Payment Type</th>
              <td>
                {list?.payment_type == 1
                  ? "Manual pay"
                  : list?.payment_type == 2
                  ? "UPI"
                  : ""}
              </td>
            </tr>
            <tr>
              <th>Ride Start Date and Time</th>
              <td>
                {list?.ride_started_time
                  ? moment(list?.ride_started_time).format(
                      "DD/MM/YYYY, hh:mm A"
                    )
                  : moment(list?.ride_started_time).format(
                      "DD/MM/YYYY, hh:mm A"
                    )}
              </td>
            </tr>
            <tr>
              <th>Ride End Date and Time</th>
              <td>
                {list?.ride_started_time
                  ? moment(list?.updated_at).format("DD/MM/YYYY, hh:mm A")
                  : moment(list?.updated_at).format("DD/MM/YYYY, hh:mm A")}
              </td>
            </tr>
          </tbody>
        </table>
        <div></div>
      </div>
    </div>
  );
};

export default Invoice;
