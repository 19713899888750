import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useState } from "react";
import TopHeader from "../../../component/TopHeader";
import { useLocation } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import swal from "sweetalert";
import { toast } from "react-toastify";

const AddSetting = () => {
  const location = useLocation();
  const type = location?.state.type;
  const Id = location?.state.id;
  const [options, setOptions] = useState();
  const [option, setOption] = useState();
  // console.log("type", type);
  // console.log("type", Id);

  const [input, setInput] = useState([]);

  // DISTANCE FARE

  const [distanceFare, setDistanceFare] = useState([
    { min_km_time: "", price: "" },
  ]);

  const addDistanceFare = () => {
    let temp = [...distanceFare];

    // console.log(temp);

    temp.push({ min_km_time: "", price: "" });
    setDistanceFare(temp);
  };

  const removeDistanceFare = (index) => {
    let temp = [...distanceFare];
    temp.splice(index, 1);
    setDistanceFare(temp);
  };

  const handleChangeDistance = (e, index) => {
    const name = e.target.name;
    const value = e.target.value;

    let temp = [...distanceFare];

    temp[index][name] = value;

    setDistanceFare(temp);
    console.log(temp);
  };

  // PEAK FARE

  const [peakFare, setPeakFare] = useState([
    { start_time: "", end_time: "", peak_fare: "" },
  ]);

  const addPeakFare = () => {
    let temp = [...peakFare];

    // console.log(temp);

    temp.push({ start_time: "", end_time: "", peak_fare: "" });
    setPeakFare(temp);
  };

  const removePeakFare = (index) => {
    let temp = [...peakFare];

    temp.splice(index, 1);
    setPeakFare(temp);
  };

  const handleChangePeak = (e, index) => {
    const name = e.target.name;
    const value = e.target.value;

    let temp = [...peakFare];

    temp[index][name] = value;

    setPeakFare(temp);
  };

  // NIGHT FARE

  const [nightFare, setnightFare] = useState([
    { start_time: "", end_time: "", night_fare: "" },
  ]);

  const addNightFare = () => {
    let temp = [...nightFare];

    // console.log(temp);

    temp.push({ start_time: "", end_time: "", night_fare: "" });
    setnightFare(temp);
  };

  const removeNightFare = (index) => {
    let temp = [...nightFare];

    temp.splice(index, 1);
    setnightFare(temp);
  };

  const handleChangeNight = (e, index) => {
    const name = e.target.name;
    const value = e.target.value;

    let temp = [...nightFare];

    temp[index][name] = value;

    setnightFare(temp);
  };

  // HANDLE CHANGE EVENT

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setInput((val) => ({ ...val, [name]: value }));
  };

  const add = (num) => {
    let formData = new FormData();

    if (num == 1) {
      console.log("1", num);

      formData.append("from", input?.from);
      formData.append("to", input?.to);
      formData.append("amount", input?.amount);
    } else if (num == 2) {
      console.log("2", num);

      formData.append("min_km_time", distanceFare[0]?.min_km_time);
      formData.append("amount", distanceFare[0]?.price);
      formData.append("type", 1);
    } else if (num == 3) {
      console.log("3", num);

      formData.append("min_km_time", input?.min_km_time);
      formData.append("amount", input?.amount);
      formData.append("type", 2);
    } else if (num == 4) {
      console.log("4", num);

      // peakFare?.map((i, index)=>(
      //   formData.append(`from_time[${i}][]`,),
      //   formData.append(`to_time `),
      //   formData.append(`percentage input`),
      //   formData.append('type', 1)
      // ))
    } else if (num == 5) {
      console.log("5", num);

      // peakFare?.map((i, index)=>(
      //   formData.append(`from_time[${i}][]`,),
      //   formData.append(`to_time `),
      //   formData.append(`percentage input`),
      //   formData.append('type', 2)
      // ))
    }
  };

  //  VIEW

  const viewList = () => {};

  useEffect(() => {
    if (Id) {
      viewList();
    }
  }, []);

  return (
    <>
      <aside>
        {type === "edit" ? (
          <TopHeader type="editFare" />
        ) : (
          <TopHeader type="addFare" />
        )}

        <article>
          <div className="p-3">
            <div className="p-3 bg-white rounded-2">
              <div className="flex-ac-jb">
                <p
                  className="permission-text txt-primary4 mb-0 mt-0 text-uppercase"
                  for=""
                >
                  Base Fare
                </p>
              </div>
              <Form className="d-flex flex-wrap">
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Fare Plan Name
                      </label>
                      <input
                        id="example-if-email"
                        name="title"
                        placeholder="fare plan name"
                        className="bg-transparent"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        From
                      </label>
                      <input
                        id="example-if-email"
                        name="from"
                        placeholder="From"
                        className="bg-transparent"
                        onChange={handleChange}
                        value={input?.from}
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        To
                      </label>
                      <input
                        placeholder="To"
                        name="to"
                        className="bg-transparent"
                        type="text"
                        onChange={handleChange}
                        value={input?.to}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Price
                      </label>
                      <input
                        placeholder="price"
                        name="amount"
                        className="bg-transparent"
                        type="text"
                        onChange={handleChange}
                        value={input?.amount}
                      />
                    </div>
                  </div>
                </div>
              </Form>
              <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
                <button
                  className="comn-btn bg-primary px-3 py-2 me-2"
                  onClick={(e) => add(1)}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>

          <div className="p-3">
            <div className="p-3 bg-white rounded-2">
              <div className="flex-ac-jb">
                <p
                  className="permission-text txt-primary4 mb-0 mt-0 text-uppercase"
                  for=""
                >
                  Distance Fare
                </p>
              </div>
              {distanceFare?.map((item, index) => (
                <>
                  <Form className="d-flex flex-wrap">
                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            KM
                          </label>
                          <input
                            id="example-if-email"
                            name="min_km_time"
                            placeholder="km"
                            className="bg-transparent"
                            type="text"
                            onChange={(e) => handleChangeDistance(e, index)}
                            value={item?.min_km_time}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Price
                          </label>
                          <input
                            placeholder="amount"
                            name="price"
                            className="bg-transparent"
                            type="text"
                            onChange={(e) => handleChangeDistance(e, index)}
                            value={item?.price}
                          />
                        </div>
                      </div>
                    </div>
                  </Form>
                  <div className="d-flex flex-row-reverse">
                    <button
                      className="comn-btn bg-primary px-3 py-2 me-2"
                      onClick={addDistanceFare}
                    >
                      Add
                    </button>
                    {distanceFare?.length > 1 ? (
                      <button
                        className="comn-btn bg-primary px-3 py-2 me-2"
                        onClick={() => removeDistanceFare(index)}
                      >
                        remove
                      </button>
                    ) : null}
                  </div>
                </>
              ))}
              <br></br>

              <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
                <button
                  className="comn-btn bg-primary px-3 py-2 me-2"
                  onClick={(e) => add(2)}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>

          <div className="p-3">
            <div className="p-3 bg-white rounded-2">
              <div className="flex-ac-jb">
                <p
                  className="permission-text txt-primary4 mb-0 mt-0 text-uppercase"
                  for=""
                >
                  Waiting Price
                </p>
              </div>
              <Form className="d-flex flex-wrap">
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Minutes
                      </label>
                      <input
                        type="text"
                        id="example-if-email"
                        placeholder="Minutes"
                        name="min_km_time"
                        className="bg-transparent"
                        onChange={handleChange}
                        value={input?.min_km_time}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Waiting Price
                      </label>
                      <input
                        type="text"
                        id="example-if-email"
                        placeholder="Waiting Price"
                        name="amount"
                        className="bg-transparent"
                        onChange={handleChange}
                        value={input?.amount}
                      />
                    </div>
                  </div>
                </div>
              </Form>

              <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
                <button
                  className="comn-btn bg-primary px-3 py-2 me-2"
                  onClick={(e) => add(3)}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>

          <div className="p-3">
            <div className="p-3 bg-white rounded-2">
              <Form className="d-flex flex-wrap">
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Apply Peak Fare
                      </label>
                      <select onChange={(e) => setOptions(e.target.value)}>
                        <option>apply peak fare</option>
                        <option value={1}>Yes</option>
                        <option value={2}>No</option>
                      </select>
                    </div>
                  </div>
                </div>
              </Form>
              {options == 1 ? (
                <>
                  {peakFare?.map((item, index) => (
                    <>
                      <Form className="d-flex flex-wrap">
                        {/* <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                          <div className="form-check ps-0">
                            <div className="px-1 col-sm-12 modal-label-input">
                              <label className="text-primary-dark" for="">
                                Day
                              </label>
                              <input
                                type="text"
                                id="example-if-email"
                                placeholder="day"
                                name="day"
                                className="bg-transparent"
                                value={item?.day}
                              />
                            </div>
                          </div>
                        </div> */}
                        <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                          <div className="form-check ps-0">
                            <div className="px-1 col-sm-12 modal-label-input">
                              <label className="text-primary-dark" for="">
                                Start Time
                              </label>
                              <input
                                type="time"
                                id="example-if-email"
                                placeholder="start time"
                                name="start_time"
                                className="bg-transparent"
                                onChange={(e) => handleChangePeak(e, index)}
                                value={item?.start_time}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                          <div className="form-check ps-0">
                            <div className="px-1 col-sm-12 modal-label-input">
                              <label className="text-primary-dark" for="">
                                End Time
                              </label>
                              <input
                                type="time"
                                id="example-if-email"
                                placeholder="end time"
                                name="end_time"
                                onChange={(e) => handleChangePeak(e, index)}
                                value={item?.end_time}
                                className="bg-transparent"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                          <div className="form-check ps-0">
                            <div className="px-1 col-sm-12 modal-label-input">
                              <label className="text-primary-dark" for="">
                                Peak Fare
                              </label>
                              <input
                                type="text"
                                id="example-if-email"
                                placeholder="peak fare"
                                name="peak_fare"
                                onChange={(e) => handleChangePeak(e, index)}
                                value={item?.peak_fare}
                                className="bg-transparent"
                              />
                            </div>
                          </div>
                        </div>
                      </Form>
                      <div className="d-flex flex-row-reverse">
                        <button
                          className="comn-btn bg-primary px-3 py-2 me-2"
                          onClick={addPeakFare}
                        >
                          Add
                        </button>
                        {peakFare.length > 1 ? (
                          <button
                            className="comn-btn bg-primary px-3 py-2 me-2"
                            onClick={() => removePeakFare(index)}
                          >
                            remove
                          </button>
                        ) : null}
                      </div>
                    </>
                  ))}
                  <br></br>
                  <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
                    <button
                      className="comn-btn bg-primary px-3 py-2 me-2"
                      onClick={(e) => add(4)}
                    >
                      Submit
                    </button>
                  </div>
                </>
              ) : null}

              <Form className="d-flex flex-wrap">
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Night Fare Detail
                      </label>
                      <select onChange={(e) => setOption(e.target.value)}>
                        <option>night fare detail</option>
                        <option value={1}>Yes</option>
                        <option value={2}>No</option>
                      </select>
                    </div>
                  </div>
                </div>
              </Form>
              {option == 1 ? (
                <>
                  {nightFare?.map((item, index) => (
                    <>
                      <Form className="d-flex flex-wrap">
                        <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                          <div className="form-check ps-0">
                            <div className="px-1 col-sm-12 modal-label-input">
                              <label className="text-primary-dark" for="">
                                Start Time
                              </label>
                              <input
                                type="time"
                                id="example-if-email"
                                placeholder="start time"
                                name="start_time"
                                onChange={(e) => handleChangeNight(e, index)}
                                value={item?.start_time}
                                className="bg-transparent"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                          <div className="form-check ps-0">
                            <div className="px-1 col-sm-12 modal-label-input">
                              <label className="text-primary-dark" for="">
                                End Time
                              </label>
                              <input
                                type="time"
                                id="example-if-email"
                                placeholder="end time"
                                name="end_time"
                                onChange={(e) => handleChangeNight(e, index)}
                                value={item?.end_time}
                                className="bg-transparent"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                          <div className="form-check ps-0">
                            <div className="px-1 col-sm-12 modal-label-input">
                              <label className="text-primary-dark" for="">
                                Night Fare
                              </label>
                              <input
                                type="text"
                                id="example-if-email"
                                placeholder="night fare"
                                name="night_fare"
                                onChange={(e) => handleChangeNight(e, index)}
                                value={item?.night_fare}
                                className="bg-transparent"
                              />
                            </div>
                          </div>
                        </div>
                      </Form>
                      <div className="d-flex flex-row-reverse">
                        <button
                          className="comn-btn bg-primary px-3 py-2 me-2"
                          onClick={addNightFare}
                        >
                          Add
                        </button>
                        {nightFare.length > 1 ? (
                          <button
                            className="comn-btn bg-primary px-3 py-2 me-2"
                            onClick={() => removeNightFare(index)}
                          >
                            remove
                          </button>
                        ) : null}
                      </div>
                    </>
                  ))}
                  <br></br>
                  <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
                    <button
                      className="comn-btn bg-primary px-3 py-2 me-2"
                      onClick={(e) => add(5)}
                    >
                      Submit
                    </button>
                  </div>
                </>
              ) : null}
              <br></br>

              {/* <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
                <button className="comn-btn bg-primary px-3 py-2 me-2">
                  Submit
                </button>
              </div> */}
            </div>
          </div>
        </article>
      </aside>
    </>
  );
};

export default AddSetting;
