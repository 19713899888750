import React from "react";
import { useNavigate } from "react-router-dom";
import { FourNotFour } from "../../assets/img";

function FourNotFourPage() {
  const navigate = useNavigate();
  return (
    <div>
      <img
        src={FourNotFour}
        style={{ width: "100%" }}
        onClick={() => navigate("/")}
      />
    </div>
  );
}

export default FourNotFourPage;
