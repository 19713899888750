import React, { useEffect, useState } from "react";
// import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../../../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPenAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Form, Tab, Tabs } from "react-bootstrap";
// import Pagination from "react-bootstrap/Pagination";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Row, Col, Pagination, Button } from "react-bootstrap";
import BTable from "react-bootstrap/Table";

import { GlobalFilter } from "../Roles/GlobalFilter";

import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";

import ReactPaginate from "react-paginate";
import swal from "sweetalert";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import moment from "moment";
import {
  useLazyPushNotifiListQuery,
  useLazyRideNotificationQuery,
  useLazyRideNotifiViewQuery,
  useLazyRideSearchViewQuery,
  usePushNotifiAddMutation,
  usePushNotifiDeleteMutation,
} from "../../../constants/redux/api/api";

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    globalFilter,
    setGlobalFilter,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      {/* show */}
      <Row className="mb-3 table-show row">
        <Col className="d-flex align-items-center">
          <p>Show</p>

          <select
            className="form-control w-auto mx-2 p-2 py-1"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
          <p>entries</p>
        </Col>
        <Col className="d-flex justify-content-end">
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          {/* <Link to="/support/add-support">
            <Button style={{ background: 'rgb(95,188,95)' }} className="btn-sm btn-round has-ripple ml-2 border-0">
              <i className="feather icon-plus" /> Add Ticket
            </Button>
          </Link> */}
        </Col>
      </Row>
      {/* show */}
      <BTable size="sm" striped bordered hover responsive {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="feather icon-arrow-down text-muted float-right" />
                      ) : (
                        <span className="feather icon-arrow-up text-muted float-right" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
      {/* pagination */}
      <Row className="justify-content-between table-show my-2">
        <Col>
          <span className="d-flex align-items-center">
            <p className="px-1">Page</p>{" "}
            <strong>
              {" "}
              {pageIndex + 1} of {pageOptions.length}{" "}
            </strong>{" "}
            <p className="px-1">|</p>
            <p className="px-1">Go to page:</p>{" "}
            <input
              type="number"
              className="form-control ml-2  p-1 py-1 text-center"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "40px" }}
            />
          </span>
        </Col>
        <Col>
          <Pagination className="justify-content-end ">
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          </Pagination>
        </Col>
      </Row>
      {/*paginations  */}
    </>
  );
}
function TableView({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    globalFilter,
    setGlobalFilter,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 40 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <BTable size="sm" striped bordered hover responsive {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="feather icon-arrow-down text-muted float-right" />
                      ) : (
                        <span className="feather icon-arrow-up text-muted float-right" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
    </>
  );
}

const PushNotification = () => {
  const navigate = useNavigate();
  const permissons = JSON.parse(localStorage.getItem("role"));

  const [addModal, setAddModal] = useState(false);
  const [image, setImage] = useState("");
  const [imagePre, setImagePre] = useState("");
  const [rideList, setRideList] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const [viewModel, setViewModel] = useState(false);
  const [firstView, setFirstView] = useState([]);
  const [secondView, setSeacondView] = useState([]);
  const [thierdView, setTriedView] = useState([]);
  const [title, setTitle] = useState("");
  const [type, setType] = useState("");
  const [titleErr, setTitleErr] = useState(false);
  const [userTypeErr, setUserTypeErr] = useState(false);

  // RTK
  const [deleteApi] = usePushNotifiDeleteMutation();
  const [addNotifyApi] = usePushNotifiAddMutation();
  const [listApi] = useLazyPushNotifiListQuery();
  const [ridedNotification] = useLazyRideNotificationQuery();
  //RTK
  const [userView] = useLazyRideSearchViewQuery();

  const apiUser = (id) => {
    userView(id)
      .unwrap()
      .then((res) => {
        console.log(res);
        // setUserList(res?.active_users);
        // setListView();
        setFirstView(res?.first_sent_notifications);
        setSeacondView(res?.second_sent_notifications);
        setTriedView(res?.third_sent_notifications);
        setViewModel(true);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const columnsView = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <>{row.index + 1}</>;
        },
      },
      {
        Header: "Driver Name",
        accessor: "driver_name",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },

      {
        Header: "Notification Id",
        accessor: "notifiable_id",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      {
        Header: "Title",
        accessor: "title",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      {
        Header: "Notification",
        accessor: "description",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
    ],
    []
  );
  const columnsView2 = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <>{row.index + 1}</>;
        },
      },
      {
        Header: "Driver Name",
        accessor: "driver_name",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },

      {
        Header: "Notification Id",
        accessor: "notifiable_id",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      {
        Header: "Title",
        accessor: "title",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      {
        Header: "Notification",
        accessor: "description",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
    ],
    []
  );
  const columnsView3 = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <>{row.index + 1}</>;
        },
      },
      {
        Header: "Driver Name",
        accessor: "driver_name",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },

      {
        Header: "Notification Id",
        accessor: "notifiable_id",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      {
        Header: "Title",
        accessor: "title",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      {
        Header: "Notification",
        accessor: "description",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
    ],
    []
  );
  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <>{row.index + 1}</>;
        },
      },
      {
        Header: "Date",
        accessor: "created_at",
        Cell: ({ value, row }) => {
          return <>{moment(value).format("DD/MM/YYYY")}</>;
        },
      },

      {
        Header: "Tittle",
        accessor: "title",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      {
        Header: "Description",
        accessor: "description",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      // {
      //   Header: "Image",
      //   accessor: "img_url",
      //   Cell: ({ value, row }) => {
      //     return <img src={value} style={{ width: "80px", height: "50px" }} />;
      //   },
      // },

      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value, row }) => {
          return (
            <React.Fragment>
              {permissons?.includes("CAN-DELETE-NOTIFICATION") ? (
                <td className="flex-ac text-center">
                  <button
                    className="comn-btn bg-primary edit-btn-svg"
                    onClick={() => deleteNotify(row.original.id)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </td>
              ) : null}
            </React.Fragment>
          );
        },
      },
    ],
    []
  );
  const columns2 = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <>{row.index + 1}</>;
        },
      },
      // {
      //   Header: "Date",
      //   accessor: "created_at",
      //   Cell: ({ value, row }) => {
      //     return <>{moment(value).format("DD/MM/YYYY")}</>;
      //   },
      // },

      {
        Header: "driver name",
        accessor: "driver.name",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      {
        Header: "user name",
        accessor: "user.name",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      // {
      //   Header: "Image",
      //   accessor: "img_url",
      //   Cell: ({ value, row }) => {
      //     return <img src={value} style={{ width: "80px", height: "50px" }} />;
      //   },
      // },

      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value, row }) => {
          return (
            <React.Fragment>
              {/* {permissons?.includes("CAN-DELETE-NOTIFICATION") ? ( */}
              <td className="flex-ac text-center">
                <button
                  style={{ margin: "0px 5px" }}
                  className="comn-btn bg-primary edit-btn-svg"
                  onClick={() =>
                    navigate("/pushnotification/view", {
                      state: { data: row.original, type: "ride" },
                    })
                  }
                >
                  <FontAwesomeIcon icon={faEye} />
                </button>
              </td>
              {/* // ) : null} */}
            </React.Fragment>
          );
        },
      },
    ],
    []
  );
  const columns3 = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <>{row.index + 1}</>;
        },
      },
      // {
      //   Header: "Date",
      //   accessor: "created_at",
      //   Cell: ({ value, row }) => {
      //     return <>{moment(value).format("DD/MM/YYYY")}</>;
      //   },
      // },

      {
        Header: "User Id",
        accessor: "user_id",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      {
        Header: "User name",
        accessor: "user.name",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      // {
      //   Header: "Description",
      //   accessor: "description",
      //   Cell: ({ value, row }) => {
      //     return <>{value}</>;
      //   },
      // },
      // {
      //   Header: "Image",
      //   accessor: "img_url",
      //   Cell: ({ value, row }) => {
      //     return <img src={value} style={{ width: "80px", height: "50px" }} />;
      //   },
      // },

      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value, row }) => {
          return (
            <React.Fragment>
              {/* {permissons?.includes("CAN-DELETE-NOTIFICATION") ? ( */}
              <td className="flex-ac text-center">
                {(row?.original?.first_sent_drivers == "[]" ||
                  row?.original?.first_sent_drivers == null ||
                  row?.original?.first_sent_drivers == "null") &&
                (row?.original?.first_sent_drivers == "[]" ||
                  row?.original?.first_sent_drivers == null ||
                  row?.original?.first_sent_drivers == "null") ? (
                  <p className="text-danger">Driver Not Found</p>
                ) : (
                  <button
                    style={{ margin: "0px 5px" }}
                    className="comn-btn bg-primary edit-btn-svg"
                    // onClick={() =>
                    // navigate("/pushnotification/view", {
                    //   state: { data: row.original, type: "search" },
                    // })
                    // }
                    onClick={() => apiUser(row?.original?.id)}
                  >
                    <FontAwesomeIcon icon={faEye} />
                  </button>
                )}
              </td>
              {/* // ) : null} */}
            </React.Fragment>
          );
        },
      },
    ],
    []
  );

  const [list, setList] = useState([]);

  const getList = () => {
    listApi()
      .unwrap()
      .then((res) => {
        setList(res?.notifications);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getRide = () => {
    ridedNotification()
      .unwrap()
      .then((res) => {
        setRideList(res?.bookings);
        setSearchList(res?.search_rides);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getList();
    getRide();
  }, []);

  const [input, setInput] = useState([]);
  const [btn, setBtn] = useState(false);

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setInput((pre) => ({ ...pre, [name]: value }));
  };

  const addNotify = () => {
    if (title == "" || type == "") {
      setTitleErr(true);
      setUserTypeErr(true);
    } else {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("description", input?.description);
      if (image?.name) {
        formData.append("image", image);
      }
      if (type) {
        formData.append("type", type);
      }

      setBtn(true);
      addNotifyApi(formData)
        .unwrap()
        .then((res) => {
          setBtn(false);
          setTitle("");
          setType("");
          setImage("");
          setImagePre("");
          if (res?.status == "success") {
            toast.success(res?.message, {
              position: "top-right",
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              autoClose: 2000,
            });
            setAddModal(false);
            getList();
          } else {
            setBtn(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const deleteNotify = (id) => {
    Swal.fire({
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete!",
    }).then((result) => {
      setBtn(true);
      if (result.isConfirmed) {
        deleteApi(id)
          .then((res) => {
            // if (res.status == "success") {
            setBtn(false);
            toast.success(res?.message, {
              position: "top-right",
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              autoClose: 2000,
            });
            getList();
            // } else {
            //   setBtn(false);
            // }
          })
          .catch((err) => {
            setBtn(false);
          });
      }
    });
  };

  return (
    <aside>
      <TopHeader type="admin" />
      <article className="p-3">
        <Tabs
          defaultActiveKey="Detail"
          id="uncontrolled-tab-example"
          className="mb-3"
          // onSelect={(e) => setActive(e)}
          fill
        >
          <Tab eventKey="Detail" title="pushnotification">
            <div className="p-2 bg-white rounded-2">
              {/* <div className="ad-btn w-100 flex-ac-jb p-2 d-flex justify-content-end"> */}
                {/* <div className="flex-ac-jc"> */}
                  {permissons?.includes("CAN-ADD-NOTIFICATION") ? (
                    <button
                      onClick={() => setAddModal(true)}
                      className="comn-btn bg-primary1 px-3 py-2"
                      style={{
                        width: "150px",
                        margin: "20px 0 10px auto",
                        display: "block",
                      }}
                    >
                      Add Notification
                    </button>
                  ) : null}
                {/* </div> */}
              {/* </div> */}
              <Table columns={columns} data={list} />
            </div>
          </Tab>
          <Tab eventKey="Vechile" title="Booking Notification">
            <div className="p-2 bg-white rounded-2">
              <Table columns={columns2} data={rideList} />
            </div>
          </Tab>
          <Tab eventKey="message" title="Search Ride Notification">
            <div className="p-2 bg-white rounded-2">
              <Table columns={columns3} data={searchList} />
            </div>
          </Tab>
        </Tabs>
      </article>

      <Modal
        className="table-modal"
        show={addModal}
        onHide={() => setAddModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <div className="px-2 py-2 col-12 d-flex flex-wrap">
          <div className="px-1 col-sm-12 modal-label-input">
            <h4>Add PushNotification</h4>
          </div>
          <div className="px-1 col-sm-6 modal-label-input">
            <label className="text-primary-dark" for="">
              Tittle
            </label>
            <input
              type="title"
              id="example-if-email"
              name="title"
              placeholder="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            {title == "" && titleErr && (
              <span className="err">Select Title</span>
            )}
          </div>
          <div className="px-1 col-sm-6 modal-label-input">
            <label className="text-primary-dark" for="">
              Send To Type
            </label>
            <select
              name="type"
              value={type}
              onChange={(e) => {
                setType(e.target.value);
              }}
            >
              <option selected>select type</option>
              <option value={2}>Users</option>
              <option value={1}>Drivers</option>
            </select>
            {type == "" && userTypeErr && (
              <span className="err">Select Type</span>
            )}
          </div>
          <div className="form-group col-12 col-sm-12 ps-0">
            <div className="form-check ps-0">
              <div className="px-1 col-sm-12 modal-label-input">
                <label className="text-primary-dark" for="">
                  Description
                </label>
                <textarea
                  placeholder="Description"
                  type="text"
                  className="bg-transparent"
                  cols="30"
                  rows="10"
                  name="description"
                  onChange={handleChange}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="px-1 col-sm-6 modal-label-input">
            <label className="text-primary-dark" for="">
              Image
            </label>
            <input
              type="file"
              id="example-if-email"
              name="img"
              placeholder="img"
              onChange={(e) => {
                setImage(e.target.files[0]);
                setImagePre(URL.createObjectURL(e.target.files[0]));
              }}
            />
            {imagePre ? (
              <div className="w-90 prev-Image">
                <img className="w-100" src={imagePre} />
              </div>
            ) : null}
          </div>
        </div>
        <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
          <button
            onClick={() => {
              setAddModal(false);
              setImage("");
              setImagePre("");
            }}
            className="comn-btn bg-hash2 px-3 py-2 me-2"
          >
            Cancel
          </button>
          <button
            type="button"
            className="comn-btn bg-success px-3 py-2 me-2"
            data-dismiss="modal"
            onClick={() => addNotify()}
            // disabled={btn ? true : false}
          >
            Submit
          </button>
        </div>
      </Modal>
      <Modal
        className="table-modal"
        show={viewModel}
        onHide={() => setViewModel(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
       <div className="px-2 py-2 col-12 d-flex flex-wrap" style={{ overflowY:"scroll"}}>
          <div className="px-1 col-sm-12 modal-label-input">
            <h4 className="">First Send Driver</h4>
          </div>
          <div className="p-2 bg-white w-100 rounded-2 ">
            <TableView columns={columnsView} data={firstView} />
          </div>
          {secondView?.length > 0 ? (
            <>
              <div className="px-1 col-sm-12 modal-label-input">
                <h4>Second Send Driver</h4>
              </div>
              <div className="p-2 bg-white w-100 rounded-2 ">
                <TableView columns={columnsView2} data={secondView} />
              </div>
            </>
          ) : (
            ""
          )}
          {thierdView?.length > 0 ? (
            <>
              <div className="px-1 col-sm-12 modal-label-input">
                <h4>Third Send Driver</h4>
              </div>
              <div className="p-2 bg-white w-100 rounded-2 ">
                <TableView columns={columnsView3} data={thierdView} />
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </Modal>
    </aside>
  );
};

export default PushNotification;
