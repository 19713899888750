import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../../../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ReactPaginate from "react-paginate";
import Rejected from "./Rejected";
import Cancelled from "./Cancelled";
import MissedOut from "./MissedOut";

const data = [
  {
    id: 1,
    name: "person1",
    mobile: "#001",
    vehicleNo: "TN18AT6353",
    rc: "86753798545",
    status: "Active",
  },
  {
    id: 2,
    name: "person2",
    mobile: "#002",
    vehicleNo: "TN18AT6354",
    rc: "86753798546",
    status: "Active",
  },
  {
    id: 3,
    name: "person3",
    mobile: "#003",
    vehicleNo: "TN18AT6355",
    rc: "86753798547",
    status: "Active",
  },
  {
    id: 4,
    name: "person4",
    mobile: "#004",
    vehicleNo: "TN18AT6356",
    rc: "86753798548",
    status: "DeActive",
  },
  {
    id: 5,
    name: "person5",
    mobile: "#005",
    vehicleNo: "TN18AT6353",
    rc: "86753798545",
    status: "Active",
  },
];

function CancelRide() {
  return (
    <aside>
      <TopHeader type="cancelled" />
      <article>
        <div className="p-3">
          <Tabs
            defaultActiveKey="Detail-View"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="Detail-View" title="Rejected">
              <Rejected />
            </Tab>
            <Tab eventKey="Detail" title="Cancelled">
              <Cancelled />
            </Tab>
            <Tab eventKey="View" title="Missed Out">
              <MissedOut />
            </Tab>
          </Tabs>
        </div>
      </article>
    </aside>
  );
}

export default CancelRide;
