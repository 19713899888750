import { isRejectedWithValue } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { saveAlertMessage } from "./alertMessage";
import { toast } from "react-toastify";

export const rtkQueryErrorLogger =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    // console.log("action", action);
    if (isRejectedWithValue(action)) {
      console.log(action);

      if (action?.payload?.status == 400) {
        toast.error(action?.payload?.data?.message, {
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          autoClose: 2000,
        });
        // alert(action?.payload?.data?.message);
        // dispatch(saveAlertMessage(action?.payload?.data?.message));
      } else if (action?.payload?.status == 401) {
        // UNAUTHENTICATED ACCESS
        // dispatch(saveAlertMessage(action?.payload?.data?.message));
        toast.error(action?.payload?.data?.message, {
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          autoClose: 2000,
        });
        setTimeout(() => {
          localStorage.clear();
          window.location.reload();
        }, 1500);
      } else if (action?.payload?.status == 500) {
        // alert("HTTP ERROR 500");
        // dispatch(saveAlertMessage("HTTP ERROR 500"));
        toast.error(action?.payload?.data?.message, {
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          autoClose: 2000,
        });
      } else if (action?.payload?.data?.message) {
        // alert(action?.payload?.data?.message);
        // dispatch(saveAlertMessage(action?.payload?.data?.message));
        toast.error(action?.payload?.data?.message, {
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          autoClose: 2000,
        });
      } else {
        // alert("SERVER ERROR");
        // dispatch(saveAlertMessage("NETWORK ERROR"));/
        toast.error(action?.payload?.data?.message, {
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          autoClose: 2000,
        });
      }
      // console.warn("We got a rejected action!");
      // toast.warn({ title: "Async error!", message: action.error.data.message });
    }

    return next(action);
  };
