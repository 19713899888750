import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

import {
  faBell,
  faCalendar,
  faHospital,
} from "@fortawesome/free-regular-svg-icons";
import {
  faBed,
  faCoins,
  faUserDoctor,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Col, Card, Row } from "react-bootstrap";
import TopHeader from "../../../component/TopHeader";
import moment from "moment/moment";
import { MdElectricRickshaw, MdOutlineElectricRickshaw } from "react-icons/md";
import { HiOutlineUsers } from "react-icons/hi";
import { VscLayersActive } from "react-icons/vsc";
import { GrCompliance } from "react-icons/gr";
import { DivIcon } from "leaflet";
import { useSelector } from "react-redux";
import {
  useLazyDashboardPayFilterQuery,
  useLazyDashboardPayQuery,
  useLazyDashboardPieQuery,
  useLazyDashboardQuery,
  useLazyDashboardRevenueFilterQuery,
  useLazyDashboardRevenueQuery,
} from "../../../constants/redux/api/api";

const Dashboard = () => {
  const [list, setList] = useState([]);
  const selector = useSelector((state) => state.saveTokens);
  //RTK
  const [dashboardApi] = useLazyDashboardQuery();
  const [dashboarPieApi] = useLazyDashboardPieQuery();
  const [dashboardRevenueFilterApi] = useLazyDashboardRevenueFilterQuery();
  const [dashboardRevenueApi] = useLazyDashboardRevenueQuery();
  const [dashpayFilterApi] = useLazyDashboardPayQuery();
  const [dashboardPayFilterApi] = useLazyDashboardPayFilterQuery();

  const getList = () => {
    dashboardApi()
      .unwrap()
      .then((res) => {
      setList(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // console.log(list,"list");

  // PIE CHART

  const [supports, setSupports] = useState([]);
  const [ride, setRide] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [pie, setPie] = useState([]);

  const getPieChart = () => {
    dashboarPieApi()
      .then((res) => {
        console.log(res);
        
        setPie(res);
        let support = [];
        let rides = [];
        let subscription = [];

        support.push(res?.data?.open_support_percentage + res?.data?.close_support_percentage)
        support.push(res?.data?.open_support_percentage);
        support.push(res?.data?.close_support_percentage);

        setSupports(support);

        rides.push(res?.data?.cancelled_ride_percentage);
        rides.push(res?.data?.missed_ride_percentage);
        rides.push(res?.data?.rejected_ride_percentage);

        setRide(rides);

        
        subscription.push(res?.data?.active_subs_percentage);
        subscription.push(100-res?.data?.active_subs_percentage)
        setSubscriptions(subscription);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // GRAPH
  // REVENU
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [revenue, setRevenu] = useState([]);
  const [revenueDate, setRevenuDate] = useState([]);
  const [pay, setpay] = useState([]);
  const [payDate, setPayDate] = useState([]);

  const fromDate = (value) => {
    setStart(value);
    setEnd("");
  };

  const toDate = (value) => {
    setEnd(value);
  };

  const revenueGraph = () => {
    let params = `?start_date=${start}&end_date=${end}`;

    if (start && end) {
      dashboardRevenueFilterApi(params)
        .unwrap()
        .then((res) => {
          // console.log(res,"res");
          const temp =[]
          res?.revenue?.map(item=>{
            temp.push(item)
          })
          setRevenu(temp)
         const date =[]
          res?.date?.map(item=>{
            date.push(item)
          })
          setRevenuDate(date)
        })
        .catch((err) => {
          console.log(err)
        });
    } else if (!start && !end) {
      dashboardRevenueApi()
        .unwrap()
        .then((res) => {
          // console.log(res,"res");
          const temp =[]
          res?.revenue?.map(item=>{
            temp.push(item)
          })
          setRevenu(temp)
         const date =[]
          res?.date?.map(item=>{
            date.push(item)
          })
          setRevenuDate(date)
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const payTaxGraph = () => {
    let params = `?start_date=${started}&end_date=${ended}`;

    if (started && ended) {
      dashboardPayFilterApi(params)
        .unwrap()
        .then((res) => {
          const temp =[]
          res?.tax_amt?.map(item=>{
            temp.push(item)
          })
          setpay(temp)
         const date =[]
          res?.date?.map(item=>{
            date.push(item)
          })
          setPayDate(date)
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (!started && !ended) {
      dashpayFilterApi()
        .unwrap()
        .then((res) => {
          const temp =[]
          res?.tax_amt?.map(item=>{
            temp.push(item)
          })
          setpay(temp)
         const date =[]
          res?.date?.map(item=>{
            date.push(item)
          })
          setPayDate(date)
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // PAY TAX
  const [started, setStarted] = useState();
  const [ended, setEnded] = useState();
  const [payTax, setPayTax] = useState([]);
  const [payTaxDate, setPayTaxDate] = useState([]);

  const fromDates = (value) => {
    setStarted(value.target.value);
    setEnded("");
  };

  const toDates = (value) => {
    setEnded(value.target.value);
  };

  // useEffect(() => {
  //   getList();
  //   getPieChart();
  // }, []);

  // useEffect(() => {
  //   revenueGraph();
  // }, [start, end]);

  // useEffect(() => {
  //   payTaxGraph();
  // }, [started, ended]);
  useEffect(() => {
    getList();
    getPieChart();
  }, []);

  useEffect(() => {
    revenueGraph();
  }, [start, end]);

  useEffect(() => {
    payTaxGraph();
  }, [started, ended]);

  // LINE CHART

  const PayTax = {
    height: 300,
    type: "line",
    options: {
      chart: {
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      colors: ["#c1adea"],
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          gradientToColors: ["#c1adea"],
          shadeIntensity: 0.5,
          type: "horizontal",
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      stroke: {
        width: 3,
        // curve: "smooth",
      },
      xaxis: {
        categories: payDate,
      },
      markers: {
        size: 5,
        colors: ["#9370da"],
        opacity: 0.9,
        strokeWidth: 2,
        hover: {
          size: 7,
        },
      },
    },
    series: [
      {
        name: "PayTax",
        data:pay,
      },
    ],
  };

  const Revenue = {
    height: 300,
    type: "line",
    options: {
      chart: {
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      colors: ["#c1adea"],
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          gradientToColors: ["#c1adea"],
          shadeIntensity: 0.5,
          type: "horizontal",
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      stroke: {
        width: 3,
        // curve: "smooth",
      },
      xaxis: {
        // categories: ["12-01-2023", "12-02-2023", "12-03-2023"],
        categories:revenueDate,
      },
      markers: {
        size: 5,
        colors: ["#9370da"],
        opacity: 0.9,
        strokeWidth: 2,
        hover: {
          size: 7,
        },
      },
    },
    series: [
      {
        name: "Revenue",
        // data: ["1200", "3000", "4000"],
        data: revenue,
      },
    ],
  };

  // PIE CHART

  const Ticket = {
    height: 150,
    type: "pie",
    options: {
      dataLabels: {
        enabled: true,
      },
      plotOptions: {
        pie: {
          size: "75%",
        },
      },
      labels: ["Total", "Open", "Closed"],
      legend: {
        show: false,
      },
      tooltip: {
        theme: "datk",
      },
      grid: {
        padding: {
          top: 20,
          right: 0,
          bottom: 0,
          left: 0,
        },
      },
      colors: ["#41DBAA", "#ffc901", "#DC143C"],
      fill: {
        opacity: [1, 1],
      },
      stroke: {
        width: 2,
      },
    },
    // series: [150, 90, 60],
    series: supports,
  };

  const cancelled = {
    height: 150,
    type: "pie",
    options: {
      dataLabels: {
        enabled: true,
      },
      plotOptions: {
        pie: {
          size: "75%",
        },
      },
      labels: ["Cancelled", "Missed", "Rejected"],
      legend: {
        show: false,
      },
      tooltip: {
        theme: "datk",
      },
      grid: {
        padding: {
          top: 20,
          right: 0,
          bottom: 0,
          left: 0,
        },
      },
      colors: ["#41DBAA", "#ffc901", "#DC143C"],
      fill: {
        opacity: [1, 1],
      },
      stroke: {
        width: 2,
      },
    },
    // series: [50, 20, 10],
    series: ride,
  };

  const subscription = {
    height: 150,
    type: "pie",
    options: {
      dataLabels: {
        enabled: true,
      },
      plotOptions: {
        pie: {
          size: "75%",
        },
      },
      labels: ["Total Subscription", "Active Subscription"],
      legend: {
        show: false,
      },
      tooltip: {
        theme: "datk",
      },
      grid: {
        padding: {
          top: 20,
          right: 0,
          bottom: 0,
          left: 0,
        },
      },
      colors: ["#41DBAA", "#ffc901"],
      fill: {
        opacity: [1, 1],
      },
      stroke: {
        width: 2,
      },
    },
    // series: [50, 90],
    series: subscriptions,
  };

  return (
    <aside>
      <TopHeader type="dashboard" />
      <article className="w-90">
        <div className="row">
          <Col xs={12} sm={6} lg={4} className="my-2">
            <div className="dash-box px-3 py-2">
              <div className="">
                <button className="bg-danger-gradient">
                  {/* <FontAwesomeIcon icon={faCoins} /> */}
                  <MdElectricRickshaw />
                </button>
                <h6 className="text-start">Total</h6>
                <h5 className="text-start">Users</h5>
              </div>
              {/* <h3 className="text-end text-">{list?.users}</h3> */}
              <h3 className="text-end text-">{list?.users}</h3>
            </div>
          </Col>
          <Col xs={12} sm={6} lg={4} className="my-2">
            <div className="dash-box px-3 py-2">
              <div className="">
                <button className="bg-primary-gradient">
                  {/* <FontAwesomeIcon icon={faCoins} /> */}
                  <HiOutlineUsers />
                </button>
                <h6 className="text-start">Total</h6>
                <h5 className="text-start">Passengers</h5>
              </div>
              <h3 className="text-end text-">{list?.passengers}</h3>
            </div>
          </Col>
          <Col xs={12} sm={6} lg={4} className="my-2">
            <div className="dash-box px-3 py-2">
              <div className="">
                <button className="bg-secondary-gradient">
                  {/* <FontAwesomeIcon icon={faCoins} /> */}
                  <MdOutlineElectricRickshaw />
                </button>
                <h6 className="text-start">Total</h6>
                <h5 className="text-start">Drivers</h5>
              </div>
              <h3 className="text-end text-">{list?.drivers}</h3>
            </div>
          </Col>
          <Col xs={12} sm={6} lg={4} className="my-2">
            <div className="dash-box px-3 py-2">
              <div className="">
                <button className="bg-success-gradient">
                  {/* <FontAwesomeIcon icon={faCoins} /> */}
                  <MdOutlineElectricRickshaw />
                </button>
                <h6 className="text-start">Active</h6>
                <h5 className="text-start">Drivers</h5>
              </div>
              <h3 className="text-end text-">{list?.active_drivers}</h3>
            </div>
          </Col>
          <Col xs={12} sm={6} lg={4} className="my-2">
            <div className="dash-box px-3 py-2">
              <div className="">
                <button className="bg-prim-gradient">
                  {/* <FontAwesomeIcon icon={faCoins} /> */}
                  <MdOutlineElectricRickshaw />
                </button>
                <h6 className="text-start">Offline</h6>
                <h5 className="text-start">Drivers</h5>
              </div>
              <h3 className="text-end text-">{list?.offline_drivers}</h3>
            </div>
          </Col>
          <Col xs={12} sm={6} lg={4} className="my-2">
            <div className="dash-box px-3 py-2">
              <div className="">
                <button className="bg-danger-gradient">
                  {/* <FontAwesomeIcon icon={faCoins} /> */}
                  <MdElectricRickshaw />
                </button>
                <h6 className="text-start">Approved</h6>
                <h5 className="text-start">Drivers</h5>
              </div>
              <h3 className="text-end text-">{list?.approved_drivers}</h3>
            </div>
          </Col>
          <Col xs={12} sm={6} lg={4} className="my-2">
            <div className="dash-box px-3 py-2">
              <div className="">
                <button className="bg-primary-gradient">
                  {/* <FontAwesomeIcon icon={faCoins} /> */}
                  <MdOutlineElectricRickshaw />
                </button>
                <h6 className="text-start">Pending Approval</h6>
                <h5 className="text-start">Drivers</h5>
              </div>
              <h3 className="text-end text-">
                {list?.pending_approval_drivers}
              </h3>
            </div>
          </Col>
          <Col xs={12} sm={6} lg={4} className="my-2">
            <div className="dash-box px-3 py-2">
              <div className="">
                <button className="bg-secondary-gradient">
                  {/* <FontAwesomeIcon icon={faCoins} /> */}
                  <GrCompliance />
                </button>
                <h6 className="text-start">Completed</h6>
                <h5 className="text-start">Rides</h5>
              </div>
              <h3 className="text-end text-">{list?.completed_rides}</h3>
            </div>
          </Col>
          <Col xs={12} sm={6} lg={4} className="my-2">
            <div className="dash-box px-3 py-2">
              <div className="">
                <button className="bg-success-gradient">
                  {/* <FontAwesomeIcon icon={faCoins} /> */}
                  <VscLayersActive />
                </button>
                <h6 className="text-start">Active</h6>
                <h5 className="text-start">Rides</h5>
              </div>
              <h3 className="text-end text-">{list?.active_rides}</h3>
            </div>
          </Col>
          {/* <Col xs={6} sm={4} lg={3} className="p-2">
              <div className="dash-box px-3 py-2">
                <div className="">
                  <button className="bg-prim-gradient">
                    <FontAwesomeIcon icon={faCoins} />
                  </button>
                  <h6 className="text-start">Offline</h6>
                  <h5 className="text-start">Rides</h5>
                </div>
                <h3 className="text-end text-">890</h3>
              </div>
            </Col> */}
        </div>

        <Row className="my-4 chart-dash">
          <Col xs={12} sm={6} lg={4} className="my-2 px-2">
            <Card>
              <Card.Header>
                <b>Open Tickets - Help & Support</b>
              </Card.Header>
              <Card.Body>
                <Chart {...Ticket} />
                <Row className="mt-3 text-center">
                  <Col>
                    <h5 className="m-0">
                      <i className="fas fa-circle f-10 m-r-5 text-success" />
                      {pie?.data?.total_support}
                    </h5>
                    {/* <Link style={{ color: "#000" }} to="#"> */}
                    <p>Total</p>
                    {/* </Link> */}
                  </Col>
                  <Col>
                    <h5 className="m-0">
                      <i className="fas fa-circle text-warning f-10 m-r-5" />
                      {pie?.data?.open_support}
                    </h5>
                    {/* <Link style={{ color: "#000" }} to="#"> */}
                    <p>Open</p>
                    {/* </Link> */}
                  </Col>
                  <Col>
                    <h5 className="m-0">
                      <i className="fas fa-circle text-warning f-10 m-r-5" />
                      {pie?.data?.close_support}
                    </h5>
                    {/* <Link style={{ color: "#000" }} to="#"> */}
                    <p>Closed</p>
                    {/* </Link> */}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={6} lg={4} className="my-2 px-2">
            <Card>
              <Card.Header>
                <b>Cancelled Rides</b>
              </Card.Header>
              <Card.Body>
                <Chart {...cancelled} />
                <Row className="mt-3 text-center">
                  <Col>
                    <h5 className="m-0">
                      <i className="fas fa-circle f-10 m-r-5 text-success" />
                      {pie?.data?.cancelled_rides}
                    </h5>
                    {/* <Link style={{ color: "#000" }} to="#"> */}
                    <p>Cancelled</p>
                    {/* </Link> */}
                  </Col>
                  <Col>
                    <h5 className="m-0">
                      <i className="fas fa-circle text-warning f-10 m-r-5" />
                      {pie?.data?.missed_rides}
                    </h5>
                    {/* <Link style={{ color: "#000" }} to="#"> */}
                    <p>Missed</p>
                    {/* </Link> */}
                  </Col>
                  <Col>
                    <h5 className="m-0">
                      <i className="fas fa-circle text-warning f-10 m-r-5" />
                      {pie?.data?.rejected_rides}
                    </h5>
                    {/* <Link style={{ color: "#000" }} to="#"> */}
                    <p>Rejected</p>
                    {/* </Link> */}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={6} lg={4} className="my-2 px-2">
            <Card>
              <Card.Header>
                <b>Subscription</b>
              </Card.Header>
              <Card.Body>
                <Chart {...subscription} />
                <Row className="mt-3 text-center">
                  <Col>
                    <h5 className="m-0">
                      <i className="fas fa-circle f-10 m-r-5 text-success" />
                      {pie?.data?.active_subscriptions}
                    </h5>
                    {/* <Link style={{ color: "#000" }} to="#"> */}
                    <p>Active Subscription</p>
                    {/* </Link> */}
                  </Col>
                  <Col>
                    <h5 className="m-0">
                      <i className="fas fa-circle text-warning f-10 m-r-5" />
                      {pie?.data?.total_subscriptions}
                    </h5>
                    {/* <Link style={{ color: "#000" }} to="#"> */}
                    <p>Total Subscription</p>
                    {/* </Link> */}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="my-4 justify-content-center chart-dash">
          <Col md={12}>
            <Card>
              <Card.Header>
                <b>Revenue</b>
                <Col className="d-flex flex-wrap">
                  <div className="col-md-6 col-12 px-2">
                    <span className="px-2">From Date</span>
                    <input
                      type="date"
                      className="form-control"
                      onChange={(e) => fromDate(e.target.value)}
                      value={start}
                    />
                  </div>
                  <div className="col-md-6 col-12 px-2">
                    <span className="px-2"> To Date</span>
                    <input
                      type="date"
                      className="form-control"
                      onChange={(e) => toDate(e.target.value)}
                      value={end}
                    />
                  </div>
                </Col>
              </Card.Header>
              <Card.Body>
                <Chart {...Revenue} />
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="my-4 justify-content-center chart-dash">
          <Col md={12}>
            <Card>
              <Card.Header>
                <b>Pay Tax - Filtering Monthly Basis</b>
                <Col className="d-flex flex-wrap">
                  <div className="col-md-6 col-12 px-2">
                    <span className="px-2">From Date</span>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="date"
                      onChange={(e) => fromDates(e)}
                      value={started}
                    />
                  </div>
                  <div className="col-md-6 col-12 px-2">
                    <span className="px-2">To Date</span>
                    <input
                      type="date"
                      className="form-control"
                      onChange={(e) => toDates(e)}
                      value={ended}
                    />
                  </div>
                </Col>
              </Card.Header>
              <Card.Body>
                <Chart {...PayTax} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </article>
    </aside>
  );
};

export default Dashboard;
