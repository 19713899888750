import React, { useState, useEffect } from "react";
import { Col, Form, Image } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { LoginLeft, Logo } from "../../assets/img";
import Input from "../../component/UI/input";
const ResetPassword = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [pass, setPass] = useState("");
  const [nameErr, setnameErr] = useState(false);
  const [passErr, setpassErr] = useState(false);

  const submitHandle = () => {
    if (name?.length == 0 || pass?.length < 8) {
      setnameErr(true);
      setpassErr(true);
    } else {
      navigate("/dashboard");
    }
  };

  return (
    <Col xs={12} className="d-flex vh-100 log-in">
      <Col xs={12} md={6} className="left-log-in bg-primary">
        <div className="log-in-left">
          <Image className="" src={LoginLeft} />
        </div>
      </Col>
      <Col xs={12} md={6} className="vh-100 flex-ac-jc bg-background1">
        <div className="bg-background rounded-3 px-4 py-5 w-75 login-box">
          <div className="flex-ac-jc">
            <Image className="log-logo me-2" src={Logo} />
          </div>
          <h4 className="sign-in-text txt-primary4 mb-0 mt-0">
            Enter Your OTP
          </h4>
          <div class="px-0 col-sm-12 modal-label-input">
            <label class="text-primary-dark" for="">
              OTP
            </label>
            <input
              type="Name"
              id="example-if-email"
              name="name"
              //   onChange={(e) => setName(e.target.value)}
              placeholder="otp"
              className="px-2 mt-1"
            />
          </div>
          <div class="px-0 col-sm-12 modal-label-input">
            <label class="text-primary-dark" for="">
              Enter New Password
            </label>
            <input
              type="Name"
              id="example-if-email"
              name="password"
              placeholder="******"
              className="px-2 mt-1"
            />
          </div>
          <div className="d-flex">
            <button
              onClick={() => navigate("/")}
              className="comn-btn border-0 px-3 py-2 bg-primary1 text-white rounded-1 text-decoration-none me-2"
            >
              Submit
            </button>
          </div>
        </div>
      </Col>
    </Col>
  );
};

export default ResetPassword;
