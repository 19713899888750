import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../../../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ReactPaginate from "react-paginate";
import PendingList from "./pendingList";
import PaidList from "./paidList";

const data = [
  {
    id: 1,
    name: "person1",
    mobile: 948727366,
    vehicleNo: "TN18AT6353",
    rc: "86753798545",
    status: "Active",
  },
  {
    id: 2,
    name: "person2",
    mobile: 948727367,
    vehicleNo: "TN18AT6354",
    rc: "86753798546",
    status: "Active",
  },
  {
    id: 3,
    name: "person3",
    mobile: 948727368,
    vehicleNo: "TN18AT6355",
    rc: "86753798547",
    status: "Active",
  },
  {
    id: 4,
    name: "person4",
    mobile: 948727369,
    vehicleNo: "TN18AT6356",
    rc: "86753798548",
    status: "DeActive",
  },
  {
    id: 5,
    name: "person5",
    mobile: 948727366,
    vehicleNo: "TN18AT6353",
    rc: "86753798545",
    status: "Active",
  },
  {
    id: 6,
    name: "person6",
    mobile: 948727366,
    vehicleNo: "TN18AT6353",
    rc: "86753798545",
    status: "Active",
  },
  {
    id: 7,
    name: "person7",
    mobile: 948727366,
    vehicleNo: "TN18AT6353",
    rc: "86753798545",
    status: "Active",
  },
  {
    id: 8,
    name: "person8",
    mobile: 948727366,
    vehicleNo: "TN18AT6353",
    rc: "86753798545",
    status: "Active",
  },
  {
    id: 9,
    name: "person9",
    mobile: 948727366,
    vehicleNo: "TN18AT6353",
    rc: "86753798545",
    status: "Active",
  },
  {
    id: 10,
    name: "person10",
    mobile: 948727366,
    vehicleNo: "TN18AT6353",
    rc: "86753798545",
    status: "Active",
  },
  {
    id: 11,
    name: "person11",
    mobile: 948727366,
    vehicleNo: "TN18AT6353",
    rc: "86753798545",
    status: "Active",
  },
  {
    id: 12,
    name: "person12",
    mobile: 948727366,
    vehicleNo: "TN18AT6353",
    rc: "86753798545",
    status: "Active",
  },
  {
    id: 13,
    name: "person13",
    mobile: 948727366,
    vehicleNo: "TN18AT6353",
    rc: "86753798545",
    status: "Active",
  },
  {
    id: 14,
    name: "person14",
    mobile: 948727366,
    vehicleNo: "TN18AT6353",
    rc: "86753798545",
    status: "Active",
  },
  {
    id: 15,
    name: "person15",
    mobile: 948727366,
    vehicleNo: "TN18AT6353",
    rc: "86753798545",
    status: "Active",
  },
];

function PayTax() {
  const [adminData, setadminData] = useState(data);

  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [counter, setCounter] = useState(0);
  const [last, setLast] = useState(10);
  const [itemOffset, setItemOffset] = useState(0);

  const [searcharr, setSearchArr] = useState("");

  useEffect(() => {
    let temp = [...adminData];

    if (searcharr == "") {
      const endOffset = itemOffset + itemsPerPage;

      setCurrentItems(adminData?.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(adminData?.length / itemsPerPage));
    } else {
      let datas = temp?.filter((val) =>
        val?.name?.toLowerCase().includes(searcharr.toLowerCase())
      );

      setCurrentItems(datas);
    }
  }, [searcharr]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;

    setCurrentItems(adminData?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(adminData?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, adminData]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % adminData?.length;
    setItemOffset(newOffset);

    let temp2 = event.selected;
    setCounter(temp2);

    let temp = event.selected + 1 + "0";
    setLast(temp);
  };

  const setListItems = (e) => {
    const value = e.target.value;

    setItemsPerPage(parseInt(value));
  };
  return (
    <aside>
      <TopHeader type="payTax" />
      <article className="p-3">
        <Tabs
          defaultActiveKey="Detail-View"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="Detail-View" title="Pending List">
            <PendingList />
          </Tab>
          <Tab eventKey="Detail" title="Paid List">
            <PaidList />
          </Tab>
        </Tabs>
      </article>
    </aside>
  );
}

export default PayTax;
