import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../../../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPenAlt, faEye } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { bannerList, maleAvatar } from "../../../assets/img";
import Pagination from "react-bootstrap/Pagination";
import { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import moment from "moment/moment";
import { useLazyCancelViewQuery, useLazyRejectViewQuery } from "../../../constants/redux/api/api";

const ViewRides = () => {
  const navigate = useNavigate();

  const data = [
    {
      id: 1,
      name: "person1",
      from: "vadapalani",
      to: "kodambakkam",
      cost: "50rs",
      review: "****",
    },
    {
      id: 2,
      name: "person2",
      from: "vadapalani",
      to: "kodambakkam",
      cost: "50rs",
      review: "****",
    },
    {
      id: 3,
      name: "person3",
      from: "vadapalani",
      to: "kodambakkam",
      cost: "50rs",
      review: "****",
    },
    {
      id: 4,
      name: "person4",
      from: "vadapalani",
      to: "kodambakkam",
      cost: "50rs",
      review: "****",
    },
    {
      id: 5,
      name: "person5",
      from: "vadapalani",
      to: "kodambakkam",
      cost: "50rs",
      review: "****",
    },
    {
      id: 6,
      name: "person6",
      from: "vadapalani",
      to: "kodambakkam",
      cost: "50rs",
      review: "****",
    },
    {
      id: 7,
      name: "person7",
      from: "vadapalani",
      to: "kodambakkam",
      cost: "50rs",
      review: "****",
    },
    {
      id: 8,
      name: "person8",
      from: "vadapalani",
      to: "kodambakkam",
      cost: "50rs",
      review: "****",
    },
    {
      id: 9,
      name: "person9",
      from: "vadapalani",
      to: "kodambakkam",
      cost: "50rs",
      review: "****",
    },
    {
      id: 10,
      name: "person10",
      from: "vadapalani",
      to: "kodambakkam",
      cost: "50rs",
      review: "****",
    },
    {
      id: 11,
      name: "person11",
      from: "vadapalani",
      to: "kodambakkam",
      cost: "50rs",
      review: "****",
    },
    {
      id: 12,
      name: "person12",
      from: "vadapalani",
      to: "kodambakkam",
      cost: "50rs",
      review: "****",
    },
    {
      id: 13,
      name: "person13",
      from: "vadapalani",
      to: "kodambakkam",
      cost: "50rs",
      review: "****",
    },
    {
      id: 14,
      name: "person14",
      from: "vadapalani",
      to: "kodambakkam",
      cost: "50rs",
      review: "****",
    },
    {
      id: 15,
      name: "person15",
      from: "vadapalani",
      to: "kodambakkam",
      cost: "50rs",
      review: "****",
    },
  ];

  const [adminData, setadminData] = useState(data);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [counter, setCounter] = useState(0);
  const [last, setLast] = useState(10);
  const [itemOffset, setItemOffset] = useState(0);

  const [searcharr, setSearchArr] = useState("");
  //RTK
  const[cancel_rideApi]=useLazyCancelViewQuery();
  const[reject_rideApi]=useLazyRejectViewQuery();

  useEffect(() => {
    let temp = [...adminData];

    if (searcharr == "") {
      const endOffset = itemOffset + itemsPerPage;

      setCurrentItems(adminData?.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(adminData?.length / itemsPerPage));
    } else {
      let datas = temp?.filter((val) =>
        val?.name?.toLowerCase().includes(searcharr.toLowerCase())
      );

      setCurrentItems(datas);
    }
  }, [searcharr]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;

    setCurrentItems(adminData?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(adminData?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, adminData]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % adminData?.length;
    setItemOffset(newOffset);

    let temp2 = event.selected;
    setCounter(temp2);

    let temp = event.selected + 1 + "0";
    setLast(temp);
  };

  const setListItems = (e) => {
    const value = e.target.value;

    setItemsPerPage(parseInt(value));
  };
  const location = useLocation();
  const Id = location?.state?.id;
  const type = location?.state?.type;

  const [list, setList] = useState([]);
  const [lists, setLists] = useState([]);

  const getList = () => {
    if (type == "reject") {
      reject_rideApi(Id).unwrap().then((res) => {
        setList(res?.reject_ride);
        setLists(res?.rejected_drivers[0]);
      });
    } else {
      cancel_rideApi(Id).unwrap().then((res) => {
        setList(res?.cancel_ride);
      });
    }
  };

  // let start = [
  //   moment(list?.updated_at).format("DD/MM/YYYY"),
  //   moment("23:00", "HH:mm:ss").format("hh:mm A"),
  // ];

  // let time = "23:00";

  // console.log(moment(time, "HH:mm:ss").format("hh:mm A"));
  // console.log(start);

  useEffect(() => {
    if (Id) {
      getList();
    }
  }, []);

  return (
    <aside>
      <TopHeader type="view-rides" />
      <article className="p-3">
        <article>
          <div className="p-3">
            <div className="p-3 bg-white rounded-2">
              <div className="flex-ac-jb">
                <p
                  className="permission-text txt-primary4 mb-0 mt-0 text-uppercase"
                  for=""
                ></p>
                <img
                  className="avatar-img"
                  src={list?.driver?.img_url || maleAvatar}
                />
                <p
                  className="permission-text txt-primary4 mb-0 mt-0 text-uppercase"
                  for=""
                ></p>
                <img
                  className="avatar-img"
                  src={list?.user?.img_url || maleAvatar}
                />
              </div>
              <Form className="d-flex flex-wrap">
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Driver Name
                      </label>
                      <input
                        id="example-if-email"
                        name="Phone_Number"
                        placeholder="Driver Name"
                        className="bg-transparent"
                        type="text"
                        value={list?.driver?.name || lists?.name}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        User Name
                      </label>
                      <input
                        id="example-if-email"
                        name="Phone_Number"
                        placeholder="User Name"
                        className="bg-transparent"
                        type="text"
                        value={list?.user?.name}
                        disabled
                      />
                    </div>
                  </div>
                </div>

                {/* <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Ride ID
                      </label>
                      <input
                        id="example-if-email"
                        name="Phone_Number"
                        placeholder="Ride Id"
                        className="bg-transparent"
                        type="text"
                        value={"#00" + list?.id}
                        disabled
                      />
                    </div>
                  </div>
                </div> */}

                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Ride Type
                      </label>
                      <input
                        id="example-if-email"
                        name="Phone_Number"
                        placeholder="Type"
                        className="bg-transparent"
                        type="text"
                        value={
                          list?.ride_type == 1
                            ? "Ride Now"
                            : list?.ride_type == 2
                            ? "Pre Booking"
                            : list?.ride_type == 3
                            ? "Offline Booking by Admin"
                            : "-"
                        }
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Ride Status
                      </label>
                      <input
                        id="example-if-email"
                        name="Phone_Number"
                        placeholder="Status"
                        className="bg-transparent"
                        type="text"
                        value={
                          type == "reject"
                            ? "Rejected"
                            : type == "cancelled"
                            ? "Cancelled"
                            : "Missed out"
                        }
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        From
                      </label>
                      <p className="address-request textarea-height" disabled>
                        {list?.ride_detail?.pickup_address ||
                          list?.pickup_address}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        To
                      </label>
                      <p className="address-request textarea-height" disabled>
                        {list?.ride_detail?.drop_address || list?.drop_address}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Ride Start Date and Time Stamp
                      </label>
                      <input
                        type="text"
                        name="license-number"
                        id="example-if-email"
                        placeholder="Start Date"
                        className="bg-transparent"
                        value={
                          list?.created_at
                            ? moment(list?.created_at).format(
                                "DD/MM/YYYY, hh:mm A"
                              )
                            : "-"
                        }
                        disabled
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="form-group col-12 col-sm-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Ride End Date and Time stamp
                      </label>
                      <input
                        placeholder="End Date"
                        className="bg-transparent"
                        type="text"
                        value={moment(list?.updated_at).format(
                          "DD/MM/YYYY, hh:mm A"
                        )}
                        disabled
                      />
                    </div>
                  </div>
                </div> */}

                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Ride Cost
                      </label>
                      <input
                        type="text"
                        id="example-if-email"
                        placeholder="Cost"
                        className="bg-transparent"
                        value={list?.final_amount || list?.amount}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                {/* 
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Payment Method
                      </label>
                      <input
                        type="text"
                        id="example-if-email"
                        placeholder="Payment"
                        className="bg-transparent"
                        value={list?.payment_type == 1 ? "Manual pay" : "UPI"}
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group col-12 col-sm-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        User Review
                      </label>

                      {list?.ride_review?.user_rating == 1 ? (
                        <p style={{ color: "#FBC740" }}>&#9733;</p>
                      ) : list?.ride_review?.user_rating == 2 ? (
                        <p style={{ color: "#FBC740" }}>&#9733;&#9733;</p>
                      ) : list?.ride_review?.user_rating == 3 ? (
                        <p style={{ color: "#FBC740" }}>
                          &#9733; &#9733; &#9733;
                        </p>
                      ) : list?.ride_review?.user_rating == 4 ? (
                        <p style={{ color: "#FBC740" }}>
                          &#9733; &#9733; &#9733; &#9733;
                        </p>
                      ) : list?.ride_review?.user_rating == 5 ? (
                        <p style={{ color: "#FBC740" }}>
                          &#9733; &#9733; &#9733; &#9733; &#9733;
                        </p>
                      ) : (
                        <p>&#9733; &#9733; &#9733; &#9733; &#9733;</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        User Feedback
                      </label>
                      <p className="address-request textarea-height" disabled>
                        {list?.ride_review?.user_review}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Driver Review
                      </label>

                      {list?.ride_review?.driver_rating == 1 ? (
                        <p style={{ color: "#FBC740" }}>&#9733;</p>
                      ) : list?.ride_review?.driver_rating == 2 ? (
                        <p style={{ color: "#FBC740" }}>&#9733;&#9733;</p>
                      ) : list?.ride_review?.driver_rating == 3 ? (
                        <p style={{ color: "#FBC740" }}>
                          &#9733; &#9733; &#9733;
                        </p>
                      ) : list?.ride_review?.driver_rating == 4 ? (
                        <p style={{ color: "#FBC740" }}>
                          &#9733; &#9733; &#9733; &#9733;
                        </p>
                      ) : list?.ride_review?.driver_rating == 5 ? (
                        <p style={{ color: "#FBC740" }}>
                          &#9733; &#9733; &#9733; &#9733; &#9733;
                        </p>
                      ) : (
                        <p>&#9733; &#9733; &#9733; &#9733; &#9733;</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Driver Feedback
                      </label>
                      <p className="address-request textarea-height" disabled>
                        {list?.ride_review?.driver_review}
                      </p>
                    </div>
                  </div>
                </div> */}
              </Form>
            </div>
          </div>
        </article>
      </article>
    </aside>
  );
};

export default ViewRides;
