import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../../../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faPenAlt,
  faEye,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { Row, Col, Pagination, Button } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ReactPaginate from "react-paginate";
import BookingReport from "./bookingReport";
import RewardReport from "./rewardReport";
import PayTaxReport from "./patTaxReport";
import ActivityLogReport from "./activityLog";
import ReferReport from "./referReport";
import SubscriptionReport from "./subscription";
import axios from "axios";
import { toast } from "react-toastify";
import BTable from "react-bootstrap/Table";

import { GlobalFilter } from "../Roles/GlobalFilter";

import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import { BASE_URL } from "../../../constants/redux/api/constants";
import { useLazyReportsQuery } from "../../../constants/redux/api/api";

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    globalFilter,
    setGlobalFilter,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      {/* show */}
      <Row className="mb-3 table-show row">
        <Col className="d-flex align-items-center">
          <p>Show</p>

          <select
            className="form-control w-auto mx-2 p-2 py-1"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
          <p>entries</p>
        </Col>
        <Col className="d-flex justify-content-end">
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          {/* <Link to="/support/add-support">
            <Button style={{ background: 'rgb(95,188,95)' }} className="btn-sm btn-round has-ripple ml-2 border-0">
              <i className="feather icon-plus" /> Add Ticket
            </Button>
          </Link> */}
        </Col>
      </Row>
      {/* show */}
      <BTable size="sm" striped bordered hover responsive {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="feather icon-arrow-down text-muted float-right" />
                      ) : (
                        <span className="feather icon-arrow-up text-muted float-right" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
      {/* pagination */}
      <Row className="justify-content-between table-show my-2">
        <Col>
          <span className="d-flex align-items-center">
            <p className="px-1">Page</p>{" "}
            <strong>
              {" "}
              {pageIndex + 1} of {pageOptions.length}{" "}
            </strong>{" "}
            <p className="px-1">|</p>
            <p className="px-1">Go to page:</p>{" "}
            <input
              type="number"
              className="form-control ml-2  p-1 py-1 text-center"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "40px" }}
            />
          </span>
        </Col>
        <Col>
          <Pagination className="justify-content-end ">
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          </Pagination>
        </Col>
      </Row>
      {/*paginations  */}
    </>
  );
}

function ReportCopy() {
  const navigate = useNavigate();

  const [reports, setReports] = useState([]);
  const [filType, setFilType] = useState();
  const [filTypes, setFilTypes] = useState();
  const [list, setList] = useState([]);

  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  //RTK
  const [reportApi] = useLazyReportsQuery();

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <>{row.index + 1}</>;
        },
      },
      {
        Header: "Username",
        accessor: "name",
        Cell: ({ value, row }) => {
          return <span>{value}</span>;
        },
      },

      {
        Header: "Mobile",
        accessor: "amount",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      {
        Header: "Email",
        accessor: "validity",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      {
        Header: "Total Ride",
        accessor: "validitys",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
    ],
    []
  );

  const startDate = (e) => {
    setFrom(e.target.value);
    setTo("");
  };

  const ToDate = (e) => {
    setTo(e.target.value);
  };

  const types = (value) => {
    setFilType(value);
    setFilTypes("");
    setFrom("");
    setTo("");
  };

  const getList = () => {
    let params = `?type=${filType}&status=${filTypes}`;
    let param = `?type=${filType}&status=${filTypes}&start_date=${from}&end_date=${to}`;
    let para = `?type=${filType}`;
    let paras = `?type=${filType}&start_date=${from}&end_date=${to}`;

    if (filType && filTypes && from && to) {
      reportApi(param)
        .then((res) => {
          setReports(res?.data?.reports);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (filType && from && to) {
      reportApi(paras)
        .then((res) => {
          setReports(res?.data?.reports);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (filType && filTypes) {
      reportApi(params)
        .then((res) => {
          setReports(res?.data?.reports);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (filType) {
      reportApi(para)
        .then((res) => {
          console.log(res);
          setReports(res?.data?.reports);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const token = JSON.parse(localStorage.getItem("token"));

  const exelDownload = async () => {
    let report;

    if (filType == "bookings") {
      if (filTypes == 1) {
        report = "Missed Out";
      } else if (filTypes == 2) {
        report = "Cancelled";
      } else if (filTypes == 3) {
        report = "Completed Booking";
      } else if (filTypes == 4) {
        report = "Schedule Ride";
      } else if (filTypes == 5) {
        report = "Offline Booking";
      } else {
        report = "";
      }
    } else if (filType == "rewards") {
      report = "Rewards";
    } else if (filType == "pay_tax") {
      if (filTypes == 3) {
        report = "Paid PayTax";
      } else if (filTypes == 2) {
        report = "Pending PayTax";
      }
    } else if (filType == "activity_log") {
      report = "Activity Log";
    } else if (filType == "refferals") {
      if (filTypes == 1) {
        report = "User Refferals";
      } else if (filTypes == 2) {
        report = "Driver Refferals";
      }
    } else if (filType == "subscription") {
      if (filTypes == 2) {
        report = "All Subscription Plans";
      } else if (filTypes == 1) {
        report = "Active Subscription Plans";
      }
    }

    const headers = {
      "Content-Type": "blob",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    };

    let url;

    if (filType && filTypes && from && to) {
      url = `${BASE_URL}export?type=${filType}&status=${filTypes}&start_date=${from}&end_date=${to}`;
    } else if (filType && filTypes) {
      url = `${BASE_URL}export?type=${filType}&status=${filTypes}`;
      // console.log("1", url);
    } else if (filType) {
      url = `${BASE_URL}export?type=${filType}`;
      // console.log("2", url);
    } else {
      toast("Please Select Type", {
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        autoClose: 2000,
      });
    }

    const config = {
      method: "get",
      url: url,
      responseType: "arraybuffer",
      headers,
    };

    try {
      const response = await axios(config);
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", report + " report.csv");
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      throw Error(error);
    }
  };

  useEffect(() => {
    getList();
  }, [filType, filTypes, from, to]);

  return (
    <aside>
      <TopHeader type="report" />
      <article className="p-3 position-relative">
        <div className="ad-btn d-flex justify-content-end py-2">
          <button
            // onClick={downloadShowHandler}
            className="comn-btn bg-success py-2 px-3 mx-2"
            onClick={exelDownload}
          >
            <FontAwesomeIcon className="me-1" icon={faDownload} />
            Download
          </button>
        </div>
        <div className="ad-btn w-100 flex-ae-jb px-0 py-2">
          <Col md={3} xs={6} className="px-2 my-2">
            <select
              className="form-select"
              aria-label="Default select example"
              onChange={(e) => types(e.target.value)}
              value={filType}
            >
              <option selected>Type</option>
              <option value={"bookings"}>Booking</option>
              <option value={"rewards"}>Rewards</option>
              <option value={"pay_tax"}>PayTax</option>
              <option value={"activity_log"}>Active Log</option>
              <option value={"refferals"}>Refer And Earn</option>
              <option value={"subscription"}>Subscription</option>
            </select>
          </Col>
          <Col md={3} xs={6} className="px-2  my-2">
            <select
              className="form-select"
              aria-label="Default select example"
              onChange={(e) => setFilTypes(e.target.value)}
              value={filTypes}
            >
              <option>Type</option>
              {filType == "bookings" ? (
                <>
                  <option value={3}>Completed Booking</option>
                  <option value={4}>Schedule Ride</option>
                  <option value={5}>Offline Booking</option>
                  <option value={0}>Rejected</option>
                  <option value={1}>Missed Out</option>
                  <option value={2}>Cancelled</option>
                </>
              ) : filType == "pay_tax" ? (
                <>
                  <option value={3}>Paid</option>
                  <option value={2}>Pending</option>
                </>
              ) : filType == "subscription" ? (
                <>
                  <option value={2}>All Plans</option>
                  <option value={1}>Active Plans</option>
                </>
              ) : filType == "refferals" ? (
                <>
                  <option value={1}>User</option>
                  <option value={2}>Driver</option>
                </>
              ) : null}
            </select>
          </Col>
          <Col md={3} xs={6} className="px-2 my-2">
            <span>From Date</span>
            <input
              type="date"
              className="form-control"
              onChange={startDate}
              value={from}
            />
          </Col>
          <Col md={3} xs={6} className="px-2 my-2">
            <span>To Date</span>
            <input
              type="date"
              className="form-control"
              onChange={ToDate}
              value={to}
            />
          </Col>
          <div className="flex-ac-jc">
            <Form className="search-input me-2"></Form>
          </div>
        </div>

        {filType == "bookings" ? (
          <BookingReport reports={reports} filTypes={filTypes} />
        ) : filType == "rewards" ? (
          <RewardReport reports={reports} />
        ) : filType == "pay_tax" ? (
          <PayTaxReport reports={reports} />
        ) : filType == "activity_log" ? (
          <ActivityLogReport reports={reports} />
        ) : filType == "refferals" ? (
          <ReferReport reports={reports} />
        ) : filType == "subscription" ? (
          <SubscriptionReport reports={reports} />
        ) : (
          <div className="p-2 bg-white rounded-2">
            <Table columns={columns} data={list} />
          </div>
        )}
      </article>
    </aside>
  );
}

export default ReportCopy;
