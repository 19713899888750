import React, { useEffect, useState } from "react";
// import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../../../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPenAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Card, Form } from "react-bootstrap";
// import Pagination from "react-bootstrap/Pagination";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Pagination, Button } from "react-bootstrap";
import BTable from "react-bootstrap/Table";

import { GlobalFilter } from "../Roles/GlobalFilter";

import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";

import ReactPaginate from "react-paginate";
import swal from "sweetalert";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import moment from "moment/moment";
import { toast } from "react-toastify";
import {
  useLazyDriverListQuery,
  useLazyDriverStatusQuery,
} from "../../../constants/redux/api/api";

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    globalFilter,
    setGlobalFilter,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <Row className="mb-3 table-show">
        <Col className="d-flex align-items-center">
          <p>Show</p>

          <select
            className="form-control w-auto mx-2 p-2 py-1"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
          <p>entries</p>
        </Col>
        <Col className="d-flex justify-content-end">
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          {/* <Link to="/support/add-support">
            <Button style={{ background: 'rgb(95,188,95)' }} className="btn-sm btn-round has-ripple ml-2 border-0">
              <i className="feather icon-plus" /> Add Ticket
            </Button>
          </Link> */}
        </Col>
      </Row>
      <BTable size="sm" striped bordered hover responsive {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="feather icon-arrow-down text-muted float-right" />
                      ) : (
                        <span className="feather icon-arrow-up text-muted float-right" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
      <Row className="justify-content-between table-show my-2">
        <Col>
          <span className="d-flex align-items-center">
            <p className="px-1">Page</p>{" "}
            <strong>
              {" "}
              {pageIndex + 1} of {pageOptions.length}{" "}
            </strong>{" "}
            <p className="px-1">|</p>
            <p className="px-1">Go to page:</p>{" "}
            <input
              type="number"
              className="form-control ml-2  p-1 py-1 text-center"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "40px" }}
            />
          </span>
        </Col>
        <Col>
          <Pagination className="justify-content-end ">
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          </Pagination>
        </Col>
      </Row>
    </>
  );
}

const Drivers = () => {
  const navigate = useNavigate();
  const permissons = JSON.parse(localStorage.getItem("role"));

  const action = "current_status";

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <p>{row.index + 1}</p>;
        },
      },
      {
        Header: "Driver ID",
        accessor: "id",
        Cell: ({ value, row }) => {
          return <p>{"#AD" + value}</p>;
        },
      },
      {
        Header: "Driver Name",
        accessor: "name",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Mobile Number",
        accessor: "mobile",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Vehicle Number",
        accessor: "vehicle",
        Cell: ({ value, row }) => {
          let data = value.filter((i) => i?.type == 1);
          let number = data[0];
          return <p>{number?.number}</p>;
        },
      },
      {
        Header: "Driver License Number",
        accessor: "license",
        Cell: ({ value, row }) => {
          let data = value.filter((i) => i?.type == 4);
          let number = data[0];
          return <p>{number?.number}</p>;
        },
      },
      {
        Header: "Approved Date",
        accessor: "approved_date",
        Cell: ({ value, row }) => {
          return <p>{moment(value).format("DD/MM/YYYY")}</p>;
        },
      },
      {
        Header: "Duty",
        accessor: "current_status",
        Cell: ({ value, row }) => {
          return (
            <Form>
              <Form.Check
                type="switch"
                id="custom-switch"
                checked={value == 1 ? true : false}
              />
            </Form>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value, row }) => {
          return (
            <button
              className={
                value == 6
                  ? "p-2 py-1 comn-btn active-deact-btn bg-danger"
                  : "p-2 py-1 comn-btn active-deact-btn bg-success"
              }
              onClick={() => driverStatusChange(row.original.id)}
            >
              {value == 6 ? "Deactive" : "active"}
            </button>
          );
        },
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value, row }) => {
          return (
            <React.Fragment>
              {permissons?.includes("CAN-VIEW-DRIVERS") ? (
                <button
                  onClick={() =>
                    navigate("/driver/view-profile", {
                      state: { id: row.original.id },
                    })
                  }
                  className="comn-btn bg-primary edit-btn-svg"
                >
                  <FontAwesomeIcon icon={faEye} />
                </button>
              ) : null}
            </React.Fragment>
          );
        },
      },
    ],
    []
  );

  // RTK QUERY
  const [driversListApi] = useLazyDriverListQuery();
  const [driverStatus] = useLazyDriverStatusQuery();

  const [list, setList] = useState([]);
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  const startDate = (e) => {
    setFrom(e.target.value);
    setTo("");
  };

  const ToDate = (e) => {
    setTo(e.target.value);
  };

  // console.log(list);

  const getList = () => {
    let params = `?start_date=${from}&end_date=${to}`;
    if (from && to) {
      driversListApi(params)
        .unwrap()
        .then((res) => {
          let temp = [];
          res?.accepted_drivers?.map((item) => {
            let obj = {};

            obj.id = item?.id;
            obj.name = item?.name;
            obj.mobile = item?.mobile;
            obj.vehicle = item?.driver_proof;
            obj.license = item?.driver_proof;
            obj.approvedDate = item?.driver_proof;
            obj.current_status = item?.current_status;
            obj.status = item?.current_status;
            obj.approved_date = item?.accepted_rejected_date;
            obj.created_at = item?.created_at;

            temp.push(obj);
          });
          setList(temp.reverse());
        });
    } else if (!from && !to) {
      let params = "";
      driversListApi(params)
        .unwrap()
        .then((res) => {
          let temp = [];
          res?.accepted_drivers?.map((item) => {
            let obj = {};

            obj.id = item?.id;
            obj.name = item?.name;
            obj.mobile = item?.mobile;
            obj.vehicle = item?.driver_proof;
            obj.license = item?.driver_proof;
            obj.approvedDate = item?.driver_proof;
            obj.current_status = item?.current_status;
            obj.status = item?.current_status;
            obj.approved_date = item?.accepted_rejected_date;
            obj.created_at = item?.created_at;
            temp.push(obj);
          });

          setList(temp.reverse());
        });
    }
  };

  console.log("list", list);

  const driverStatusChange = (id) => {
    driverStatus(id)
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          toast.success(res?.message, {
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            autoClose: 2000,
          });
          getList();
        }
      });
  };

  useEffect(() => {
    getList();
  }, [from, to]);

  return (
    <aside>
      <TopHeader type="driver" />
      <article className="p-3">
        <div className="p-2 bg-white rounded-2">
          <div className="ad-btn w-100 flex-ae-jb px-0 py-2">
            <Col md={3} xs={6} className="px-2 my-2">
              <label>From Date</label>
              <input
                type="date"
                className="form-control"
                onChange={startDate}
                value={from}
              />
            </Col>
            <Col md={3} xs={6} className="px-2 my-2">
              <label>To Date</label>
              <input
                type="date"
                className="form-control"
                onChange={ToDate}
                value={to}
              />
            </Col>
          </div>
          <Table columns={columns} data={list} className="" />
        </div>
      </article>
    </aside>
  );
};

export default Drivers;
