import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../../../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faPenAlt,
  faTrash,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Row, Col, Pagination, Button } from "react-bootstrap";
import BTable from "react-bootstrap/Table";
import { GlobalFilter } from "../Roles/GlobalFilter";

import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";

import ReactPaginate from "react-paginate";
import swal from "sweetalert";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useDeleteZoneMutation, useImportZoneMutation, useLazyGetZoneQuery } from "../../../constants/redux/api/api";

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    globalFilter,
    setGlobalFilter,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      {/* show */}
      <Row className="mb-3 table-show row">
        <Col className="d-flex align-items-center">
          <p>Show</p>

          <select
            className="form-control w-auto mx-2 p-2 py-1"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
          <p>entries</p>
        </Col>
        <Col className="d-flex justify-content-end">
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          {/* <Link to="/support/add-support">
            <Button style={{ background: 'rgb(95,188,95)' }} className="btn-sm btn-round has-ripple ml-2 border-0">
              <i className="feather icon-plus" /> Add Ticket
            </Button>
          </Link> */}
        </Col>
      </Row>
      {/* show */}
      <BTable size="sm" striped bordered hover responsive {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="feather icon-arrow-down text-muted float-right" />
                      ) : (
                        <span className="feather icon-arrow-up text-muted float-right" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
      {/* pagination */}
      <Row className="justify-content-between table-show my-2">
        <Col>
          <span className="d-flex align-items-center">
            <p className="px-1">Page</p>{" "}
            <strong>
              {" "}
              {pageIndex + 1} of {pageOptions.length}{" "}
            </strong>{" "}
            <p className="px-1">|</p>
            <p className="px-1">Go to page:</p>{" "}
            <input
              type="number"
              className="form-control ml-2  p-1 py-1 text-center"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "40px" }}
            />
          </span>
        </Col>
        <Col>
          <Pagination className="justify-content-end ">
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          </Pagination>
        </Col>
      </Row>
      {/*paginations  */}
    </>
  );
}

const Zone = () => {
  const navigate = useNavigate();
  const permissons = JSON.parse(localStorage.getItem("role"));

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <>{row.index + 1}</>;
        },
      },
      {
        Header: "Area",
        accessor: "zone",
        Cell: ({ value, row }) => {
          return <span>{value}</span>;
        },
      },

      {
        Header: "City",
        accessor: "city",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      {
        Header: "Pincode",
        accessor: "pin_code",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },

      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value, row }) => {
          return (
            <React.Fragment>
              <button
                style={{ margin: "0px 5px" }}
                className="comn-btn bg-primary edit-btn-svg"
                onClick={() =>
                  navigate("/setting/add-zone", {
                    state: { id: row.original.id, type: "edit" },
                  })
                }
              >
                <FontAwesomeIcon icon={faPen} />
              </button>
              <button
                className="comn-btn bg-danger edit-btn-svg"
                onClick={() => deleteHandle(row.original.id)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </React.Fragment>
          );
        },
      },
    ],
    []
  );

  const [list, setList] = useState([]);
  const [open, setOpen] = useState(false);
  const [excelFile, setExcelFile] = useState("");
  const [btn, setBtn] = useState(false);
//RTK

const [getZoneApi]= useLazyGetZoneQuery();
const[deleteZoneApi]=useDeleteZoneMutation();
const [importZoneApi]=useImportZoneMutation();


const getList = () => {
  getZoneApi().unwrap().then((res) => {
    if (res?.status == "success") {
      setList(res?.zones);
    }
  });
};

const deleteHandle = (id) => {
  Swal.fire({
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete!",
  }).then((result) => {
    if (result.isConfirmed) {
      deleteZoneApi(id).unwrap().then((res) => {
        if (res?.status == "success") {
          toast.success(res?.message, {
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            autoClose: 2000,
          });
          getList();
        }
      });
    }
  });
};
  
const importExcel = () => {
  let data = {
    import_excel: excelFile,
  };
  setBtn(true);
  importZoneApi(data).then((res) => {
    if (res?.status == "success") {
      setExcelFile("");
      setOpen(false);
      setBtn(false);
      toast.success(res?.message, {
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        autoClose: 2000,
      });
    } else {
      setBtn(false);
    }
  });
};



  useEffect(() => {
    getList();
  }, []);

  return (
    <aside>
      <article className="p-3">
        <div className="p-2 bg-white rounded-2">
          <div className="ad-btn w-100 flex-ac-jb p-2 d-flex ">
            <div className="flex-ac-jc justify-content-start">
              <button
                onClick={() => setOpen(true)}
                className="comn-btn bg-primary1 px-3 py-2"
                style={{
                  width: "120px",
                  margin: "auto 0 10px auto",
                  display: "block",
                }}
              >
                Import Excel
              </button>
            </div>
            {/* <div className="flex-ac-jc justify-content-end"> */}
              <button
                onClick={() => navigate("/setting/add-zone")}
                className="comn-btn bg-primary1 px-3 py-2" 
                style={{
                  width: "120px",
                  margin: "auto 0 10px auto",
                  display: "block",
                }}
              >
                Add Location
              </button>
            {/* </div> */}
          </div>
          <Table columns={columns} data={list} />
        </div>
      </article>
      {open && (
        <div
          className="customPopup"
          style={{ zIndex: "999999", top: 0, left: 0, bottom: 0, right: 0 }}
        >
          <div className="customPopupDesign1">
            <div>
              <h6
                className="d-flex justify-content-end"
                onClick={() => {
                  setOpen(false);
                  setExcelFile("");
                }}
              >
                {" "}
                <FontAwesomeIcon icon={faXmark} />
              </h6>
              <h5 className="text-center mb-2">Excel Upload</h5>
            </div>
            <div className="d-flex flex-wrap justify-content-between mt-3">
              <input
                type={"file"}
                accept=".csv, .xlsx"
                onChange={(e) => setExcelFile(e.target.files[0])}
              />

              <button className="comn-btn btn btn-outline-info">
                <a
                  target="_blank"
                  href="https://backend.mrautokaar.com/crmapi/export-sample-zone"
                >
                  Sample File
                </a>
              </button>
            </div>
            {excelFile?.name && (
              <div className="d-flex justify-content-center mt-4 mb-0">
                <button
                  className="comn-btn btn btn-outline-primary"
                  onClick={importExcel}
                  disabled={btn ? true : false}
                >
                  Upload
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </aside>
  );
};

export default Zone;
