import { initializeApp } from "firebase/app";

import { getMessaging, getToken } from "firebase/messaging";

//Firebase Config values imported from .env file
const firebaseConfig = {
  // apiKey: "AIzaSyBsizSc256N_dwTtAh7hr6jO1W1qCX_0IM",
  // authDomain: "bhyto-web.firebaseapp.com",
  // projectId: "bhyto-web",
  // storageBucket: "bhyto-web.appspot.com",
  // messagingSenderId: "873341266953",
  // appId: "1:873341266953:web:20dbc8e31351ee77e3dcd8",
  // measurementId: "G-MW2X2NDB18",
  apiKey: "AIzaSyCHaMNY27M2ZdP05s7rapADQECD1tcV5Xo",
  authDomain: "bhyto-947cf.firebaseapp.com",
  projectId: "bhyto-947cf",
  storageBucket: "bhyto-947cf.appspot.com",
  messagingSenderId: "1030709304769",
  appId: "1:1030709304769:web:551ae9e9ca8c40d854a340",
  measurementId: "G-9WEJ8XQFEZ",
};
console.log("Firebase Config:", firebaseConfig);
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Messaging service
export const messaging = getMessaging(app);

export const getPermission = async () => {
  try {
    const permissions = await Notification.requestPermission();
    if (permissions == "granted") {
      generateToken();
    }
  } catch (error) {
    console.log("error", error);
  }
  //   console.log("permissions", permissions);
};

const generateToken = async () => {
  try {
    const token = await getToken(messaging, {
      vapidKey:
        "BMvGmVH7xFAxwSxkCR6bZjuSyebwW_BB68fM7qjmW_u6mmg0GMh5Z_FA9grPv0kZvYXHJGAeVDLgcKeR5dQv0SE",
    });

    localStorage.setItem("fcm_id", token);
  } catch (error) {
    console.log("error", error);
  }
};
