import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../../../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPenAlt, faEye } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { Row, Col, Pagination, Button } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ReactPaginate from "react-paginate";
import DriverRating from "./DriverRating";
import UserRating from "./UserRating";

function ReviewAndRating() {
  return (
    <aside>
      <TopHeader type="review-rating" />
      <article className="p-3 position-relative">
        <Tabs
          defaultActiveKey="user"
          id="fill-tab-example"
          className="tab-appoinment"
          fill
        >
          <Tab eventKey="user" title="Users">
            <UserRating />
          </Tab>
          <Tab eventKey="doctor" title="Drivers">
            <DriverRating />
          </Tab>
        </Tabs>
      </article>
    </aside>
  );
}

export default ReviewAndRating;
