import React, { useState, useEffect } from "react";
import { Col, Form, Image } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { hidePwdImg, LoginLeft, Logo, showPwdImg } from "../../assets/img";
import {
  useChangePasswordMutation,
  useLazyLogoutQuery,
} from "../../constants/redux/api/api";

const ChangePassword = () => {
  const navigate = useNavigate();
  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [oldPassErr, setOldPassErr] = useState(false);
  const [newPassErr, setNewPassErr] = useState(false);
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isRevealCPwd, setIsRevealCPwd] = useState(false);
  const [btn, setBtn] = useState(false);

  const [changePasswordApi] = useChangePasswordMutation();

  const submitHandle = () => {
    if (oldPass?.length < 8 || newPass?.length < 8) {
      setOldPassErr(true);
      setNewPassErr(true);
    } else {
      let data = {
        old_password: oldPass,
        password: newPass,
      };
      setBtn(true);
      changePasswordApi(data)
        .unwrap()
        .then((res) => {
          setBtn(false);
          if (res.status == "success") {
            toast.success(res?.message, {
              position: "top-right",
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              autoClose: 2000,
            });
            navigate("/");
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  return (
    <Col xs={12} className="d-flex vh-100 log-in">
      <Col xs={12} md={6} className="left-log-in bg-primary">
        <div className="log-in-left">
          <Image className="" src={LoginLeft} />
        </div>
      </Col>
      <Col xs={12} md={6} className="vh-100 flex-ac-jc bg-background1">
        <div className="bg-background rounded-3 px-4 py-5 w-75 login-box">
          <div className="flex-ac-jc">
            <Image className="log-logo me-2" src={Logo} />
          </div>
          <h4 className="sign-in-text txt-primary4 mb-0 mt-0">
            Change Password
          </h4>
          <div class="px-0 col-sm-12 modal-label-input">
            <label class="text-primary-dark" for="">
              Old Password
            </label>
            <div className="pwd-container">
              <input
                type={isRevealPwd ? "text" : "password"}
                name="name"
                onChange={(e) => setOldPass(e.target.value)}
                placeholder="******"
                className="px-2 mt-1"
                autocomplete="off"
              />
              <img
                src={isRevealPwd ? showPwdImg : hidePwdImg}
                onClick={() => setIsRevealPwd((prevState) => !prevState)}
              />
            </div>
            {oldPass?.length == 0 && oldPassErr && (
              <span className="err">Enter 8 digit Old Password</span>
            )}
          </div>
          <div class="px-0 col-sm-12 modal-label-input">
            <label class="text-primary-dark" for="">
              New Password
            </label>
            <div className="pwd-container">
              <input
                type={isRevealCPwd ? "text" : "password"}
                id="example-if-email"
                name="password"
                onChange={(e) => setNewPass(e.target.value)}
                placeholder="******"
                className="px-2 mt-1"
                autocomplete="off"
              />{" "}
              <img
                src={isRevealCPwd ? showPwdImg : hidePwdImg}
                onClick={() => setIsRevealCPwd((prevState) => !prevState)}
              />
            </div>
            {newPass?.length == 0 && newPassErr && (
              <span className="err">Enter 8 digit New Password</span>
            )}
          </div>
          <div className="d-flex">
            <button
              onClick={submitHandle}
              className="comn-btn border-0 px-3 py-2 bg-primary1 text-white rounded-1 text-decoration-none me-2"
              disabled={btn ? true : false}
            >
              Submit
            </button>
          </div>
        </div>
      </Col>
    </Col>
  );
};

export default ChangePassword;
