import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { Form } from "react-bootstrap";
import swal from "sweetalert";
import { useLocation, useNavigate } from "react-router-dom";
import TopHeader from "../../../component/TopHeader";
import { hidePwdImg, showPwdImg } from "../../../assets/img";
import { ToastContainer, toast } from "react-toastify";
import {
  useAdminAddMutation,
  useAdminEditMutation,
  useLazyAdminRolesQuery,
  useLazyAdminViewQuery,
} from "../../../constants/redux/api/api";

const AddAdmin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const Id = location?.state?.id;
  const type = location?.state?.type;

  const [list, setList] = useState([]);
  const [btn, setBtn] = useState(false);
  const [name, setName] = useState("");
  const [user, setUser] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [role, setRole] = useState("");
  const [pass, setPass] = useState("");
  const [cPass, setCPass] = useState("");

  const [nameErr, setNameErr] = useState(false);
  const [userErr, setUserErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [numberErr, setNumberErr] = useState(false);
  const [roleErr, setRoleErr] = useState(false);
  const [passErr, setPassErr] = useState(false);
  const [cPassErr, setCPassErr] = useState(false);
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isRevealCPwd, setIsRevealCPwd] = useState(false);

  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [cPasswordClass, setCPasswordClass] = useState("form-control");
  const [isCPasswordDirty, setIsCPasswordDirty] = useState(false);

  // RTK QUERY
  const [adminRoleApi] = useLazyAdminRolesQuery();
  const [adminViewApi] = useLazyAdminViewQuery();
  const [updateApi] = useAdminEditMutation();
  const [addApi] = useAdminAddMutation();

  // console.log(admin);

  useEffect(() => {
    if (isCPasswordDirty) {
      if (pass === cPass) {
        if (pass == "" && cPass == "") {
          setCPasswordClass("form-control");
          setShowErrorMessage(false);
        } else {
          setShowErrorMessage(false);
          setCPasswordClass("form-control is-valid");
        }
      } else if (pass !== cPass || pass.length < 6 || cPass.length < 6) {
        if (pass == "" && cPass == "") {
          setCPasswordClass("form-control");
          setShowErrorMessage(false);
        } else {
          setShowErrorMessage(true);
          setCPasswordClass("form-control is-invalid");
        }
      }
    }
  }, [cPass, pass]);

  const handleCPassword = (e) => {
    setCPass(e.target.value);
    setIsCPasswordDirty(true);
  };

  const validEmail = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);

  console.log(Id, "id");

  const addAdmins = () => {
    let formData = new FormData();

    if (Id) {
      formData.append("name", name);
      formData.append("username", user);
      formData.append("email", email);
      formData.append("mobile", number);
      formData.append("role_id", role);

      if (pass && cPass) {
        formData.append("password", pass);
        formData.append("password_confirmation", cPass);
      }

      formData.append("_method", "PUT");
      setBtn(true);

      updateApi({ id: Id, payload: formData })
        .unwrap()
        .then((res) => {
          setBtn(false);
          if (res?.status == "success") {
            toast.success(res?.message, {
              position: "top-right",
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              autoClose: 2000,
            });
            navigate("/Admin");
          } else {
            console.log("res", res);
          }
        })
        .catch((err) => {
          setBtn(false);
          console.log("err", err);
        });
    } else {
      if (
        name?.length == 0 ||
        user?.length == 0 ||
        email?.length == 0 ||
        number?.length == 0 ||
        role?.length == 0 ||
        pass?.length < 8 ||
        cPass?.length < 8
      ) {
        setNameErr(true);
        setUserErr(true);
        setEmailErr(true);
        setNumberErr(true);
        setRoleErr(true);
        setPassErr(true);
        setCPassErr(true);
      } else {
        formData.append("name", name);
        formData.append("username", user);
        formData.append("email", email);
        formData.append("mobile", number);
        formData.append("role_id", role);
        formData.append("password", pass);
        formData.append("password_confirmation", cPass);
        setBtn(true);

        addApi(formData)
          .unwrap()
          .then((res) => {
            setBtn(false);
            if (res?.status == "success") {
              toast.success(res?.message, {
                position: "top-right",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoClose: 2000,
              });
              navigate("/Admin");
            } else {
              console.log("res", res);
            }
          })
          .catch((err) => {
            setBtn(false);
            console.log("err", err);
          });
      }
    }
  };

  // ROLES API
  const getRoles = () => {
    adminRoleApi()
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          setList(res?.roles);
        }
      });
  };

  // VIEW API
  const viewAdmins = () => {
    adminViewApi(Id)
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          // console.log("res", res);
          setName(res?.admin?.name);
          setUser(res?.admin?.username);
          setEmail(res?.admin?.email);
          setNumber(res?.admin?.mobile);
          setRole(res?.admin?.role?.id);
        }
        // console.log(admin);
      });
  };

  useEffect(() => {
    getRoles();
    if (Id) {
      viewAdmins();
    }
  }, []);

  return (
    <aside>
      {type == "edit" ? (
        <TopHeader type="edit-admin" />
      ) : (
        <TopHeader type="add-admin" />
      )}
      <article className="p-3">
        <div className="p-3">
          <div className="p-3 bg-white rounded-2">
            <Form className="d-flex flex-wrap">
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Name
                    </label>
                    <input
                      type="Name"
                      id="example-if-email"
                      name="name"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                      placeholder="Name"
                    />
                    {name?.length == 0 && nameErr && (
                      <p className="err-p">Enter Name</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Username
                    </label>
                    <input
                      type="Name"
                      id="example-if-email"
                      name="username"
                      onChange={(e) => setUser(e.target.value)}
                      value={user}
                      placeholder="Username"
                    />
                    {user?.length == 0 && userErr && (
                      <p className="err-p">Enter Username</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Mobile No
                    </label>
                    <input
                      onInput={(e) => {
                        if (e.target.value.length > e.target.maxLength)
                          e.target.value = e.target.value.slice(
                            0,
                            e.target.maxLength
                          );
                        if (e.target.value.length !== 10) {
                          e.target.setCustomValidity("invalid Number");
                        } else if (e.target.value.length == 10) {
                          e.target.setCustomValidity("");

                          setNumber(e.target.value);
                        }
                      }}
                      onFocus={(e) =>
                        e.target.addEventListener(
                          "wheel",
                          function (e) {
                            e.preventDefault();
                          },
                          { passive: false }
                        )
                      }
                      maxlength={10}
                      onChange={(e) => setNumber(e.target.value)}
                      value={number}
                      type="number"
                      id="example-if-email"
                      name="number"
                      placeholder="Mobile No"
                    />
                    {number?.length == 0 && numberErr && (
                      <p className="err-p">Enter Number</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Email
                    </label>
                    <input
                      type="email"
                      id="example-if-email"
                      name="email"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      placeholder="Email"
                    />
                    {validEmail.test(email) == false && emailErr && (
                      <p className="err-p">Enter valid email</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Password
                    </label>
                    <div className="pwd-container">
                      <input
                        type={isRevealPwd ? "text" : "password"}
                        id="example-if-email"
                        name="password"
                        onChange={(e) => setPass(e.target.value)}
                        value={pass}
                        placeholder="Password"
                        autocomplete="off"
                      />
                      <img
                        src={isRevealPwd ? showPwdImg : hidePwdImg}
                        onClick={() =>
                          setIsRevealPwd((prevState) => !prevState)
                        }
                      />
                    </div>
                    {pass?.length < 8 && passErr && (
                      <p className="err-p">Enter Password</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Confirm Password
                    </label>
                    <div className="pwd-container2">
                      <input
                        type={isRevealCPwd ? "text" : "password"}
                        id="example-if-email"
                        name="password_confirmation                "
                        onChange={(e) => {
                          setCPass(e.target.value);
                          handleCPassword(e);
                        }}
                        value={cPass}
                        className={cPasswordClass}
                        placeholder="Confirm Password"
                        autocomplete="off"
                      />
                      <img
                        src={isRevealCPwd ? showPwdImg : hidePwdImg}
                        onClick={() =>
                          setIsRevealCPwd((prevState) => !prevState)
                        }
                      />
                    </div>
                    {cPass?.length < 8 && cPassErr && (
                      <p className="err-p">Enter Confirm Password</p>
                    )}
                  </div>
                </div>
              </div>{" "}
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Admin Type
                    </label>
                    <select
                      name="role"
                      id=""
                      onChange={(e) => setRole(e.target.value)}
                      value={role}
                    >
                      <option>Select Type</option>
                      {list?.map((i) => (
                        <option key={i?.id} value={i?.id}>
                          {i?.role}
                        </option>
                      ))}
                    </select>
                    {role?.length == 0 && roleErr && (
                      <p className="err-p">Select Role</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="mt-auto ms-auto">
                <button
                  type="button"
                  className="comn-btn bg-success-gradient px-3 py-2 me-2"
                  onClick={addAdmins}
                  disabled={btn || showErrorMessage ? true : false}
                >
                  Submit
                </button>
              </div>
            </Form>
          </div>
        </div>
      </article>
    </aside>
  );
};

export default AddAdmin;
