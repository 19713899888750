import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../../../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPenAlt, faEye } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import { Link, NavLink, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { Tab, Tabs } from "react-bootstrap";
import RewardClaimed from "./RewardClaimed";
import RewardRequest from "./RewardRequestList";

const Coupon = () => {
  return (
    <aside>
      <TopHeader type="coupon" />
      <article className="p-3">
        <Tabs
          defaultActiveKey="Detail-View"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="Detail-View" title="Rewards">
            <RewardRequest />
          </Tab>
          <Tab eventKey="profile" title="Reward Claimed">
            <RewardClaimed />
          </Tab>
        </Tabs>
      </article>
    </aside>
  );
};

export default Coupon;
