import React, { useEffect, useState } from "react";
// import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../../../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faPenAlt,
  faTrash,
  faHandshakeSimpleSlash,
} from "@fortawesome/free-solid-svg-icons";
import { Card, Form } from "react-bootstrap";
// import Pagination from "react-bootstrap/Pagination";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Pagination, Button } from "react-bootstrap";
import BTable from "react-bootstrap/Table";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import { GlobalFilter } from "../Roles/GlobalFilter";

import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";

import ReactPaginate from "react-paginate";
import swal from "sweetalert";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import moment from "moment/moment";
import Swal from "sweetalert2";

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    globalFilter,
    setGlobalFilter,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <Row className="mb-3 table-show">
        <Col className="d-flex align-items-center">
          <p>Show</p>

          <select
            className="form-control w-auto mx-2 p-2 py-1"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
          <p>entries</p>
        </Col>
        <Col className="d-flex justify-content-end">
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          {/* <Link to="/support/add-support">
            <Button style={{ background: 'rgb(95,188,95)' }} className="btn-sm btn-round has-ripple ml-2 border-0">
              <i className="feather icon-plus" /> Add Ticket
            </Button>
          </Link> */}
        </Col>
      </Row>
      <BTable size="sm" striped bordered hover responsive {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="feather icon-arrow-down text-muted float-right" />
                      ) : (
                        <span className="feather icon-arrow-up text-muted float-right" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
      <Row className="justify-content-between table-show my-2">
        <Col>
          <span className="d-flex align-items-center">
            <p className="px-1">Page</p>{" "}
            <strong>
              {" "}
              {pageIndex + 1} of {pageOptions.length}{" "}
            </strong>{" "}
            <p className="px-1">|</p>
            <p className="px-1">Go to page:</p>{" "}
            <input
              type="number"
              className="form-control ml-2  p-1 py-1 text-center"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "40px" }}
            />
          </span>
        </Col>
        <Col>
          <Pagination className="justify-content-end ">
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          </Pagination>
        </Col>
      </Row>
    </>
  );
}

const OfflineBooking = () => {
  const navigate = useNavigate();
  const permissons = JSON.parse(localStorage.getItem("role"));

  const action = "total_hrs";

  const [list, setList] = useState([]);
  const [lists, setLists] = useState([]);
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  const startDate = (e) => {
    setFrom(e.target.value);
    setTo("");
  };

  const ToDate = (e) => {
    setTo(e.target.value);
  };

  const getList = () => {
    let params = `?start_date=${from}&end_date=${to}`;
    if (from && to) {
    } else if (!from && !to) {
    }
  };

  useEffect(() => {
    getList();
  }, [from, to]);

  const [froms, setFroms] = useState();
  const [tos, setTos] = useState();

  const startDates = (e) => {
    setFrom(e.target.value);
    setTo("");
  };

  const ToDates = (e) => {
    setTo(e.target.value);
  };

  const getLists = () => {
    let params = `?start_date=${from}&end_date=${tos}`;
    if (froms && tos) {
    } else if (!froms && !tos) {
    }
  };

  const cancelRide = (id) => {
    Swal.fire({
      text: "Do you want cancel this ride!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Cancel!",
    }).then((result) => {
      if (result.isConfirmed) {
        let data = {
          search_ride_id: id,
        };
      }
    });
  };

  useEffect(() => {
    getLists();
  }, [froms, tos]);

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <p>{row.index + 1}</p>;
        },
      },

      {
        Header: "Ride ID",
        accessor: "id",
        Cell: ({ value, row }) => {
          return <p>{"#" + value}</p>;
        },
      },
      {
        Header: "Ride Booked Date and Time Stamp",
        accessor: "created_at",
        Cell: ({ value, row }) => {
          return <p>{moment(value).format("DD/MM/YYYY hh:mm A")}</p>;
        },
      },
      {
        Header: "Passenger Name",
        accessor: "user.name",
        Cell: ({ value, row }) => {
          return (
            <p
              style={{
                cursor: "pointer",
                "text-decoration-line": "underline",
                background: "none",
                color: "var(--bs-link-color)",
              }}
              onClick={() =>
                navigate("/user/view-profile", {
                  state: { id: row.original.user.id },
                })
              }
            >
              {value}
            </p>
          );
        },
      },
      {
        Header: "From",
        accessor: "pickup_address",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "To",
        accessor: "drop_address",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Distance",
        accessor: "distance",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Cost",
        accessor: "amount",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Ride Duration",
        accessor: "total_hrs",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value, row }) => {
          return (
            <p
              className={
                value == 0
                  ? "text-primary"
                  : value == 1
                  ? "text-danger"
                  : value == 2
                  ? "text-primary"
                  : value == 3
                  ? "text-danger"
                  : value == 4
                  ? "text-warning"
                  : " "
              }
            >
              {value == 0
                ? "Searching"
                : value == 1
                ? "Driver Not Allocated"
                : value == 2
                ? "Ride Allocated"
                : value == 3
                ? "Cancelled"
                : value == 4
                ? "Pre Booking"
                : " "}
            </p>
          );
        },
      },

      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value, row }) => {
          return (
            <React.Fragment>
              <div className="d-flex justify-content-between">
                <button
                  className="comn-btn bg-primary edit-btn-svg"
                  onClick={() =>
                    navigate("/offline-booking/view-booking", {
                      state: { data: row.original },
                    })
                  }
                >
                  <FontAwesomeIcon icon={faEye} />
                </button>{" "}
                <button
                  className="comn-btn bg-primary edit-btn-svg mx-2"
                  onClick={() => cancelRide(row.original.id)}
                >
                  <FontAwesomeIcon icon={faHandshakeSimpleSlash} />
                </button>
              </div>
            </React.Fragment>
          );
        },
      },
    ],
    []
  );

  const columns1 = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <p>{row.index + 1}</p>;
        },
      },

      {
        Header: "Ride ID",
        accessor: "id",
        Cell: ({ value, row }) => {
          return <p>{"#" + value}</p>;
        },
      },
      {
        Header: "Ride Booked Date and Time Stamp",
        accessor: "created_at",
        Cell: ({ value, row }) => {
          return <p>{moment(value).format("DD/MM/YYYY hh:mm A")}</p>;
        },
      },
      {
        Header: "Passenger Name",
        accessor: "user.name",
        Cell: ({ value, row }) => {
          return (
            <p
              style={{
                cursor: "pointer",
                "text-decoration-line": "underline",
                background: "none",
                color: "var(--bs-link-color)",
              }}
              onClick={() =>
                navigate("/user/view-profile", {
                  state: { id: row.original.user.id },
                })
              }
            >
              {value}
            </p>
          );
        },
      },
      {
        Header: "From",
        accessor: "ride_detail.pickup_address",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "To",
        accessor: "ride_detail.drop_address",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Distance",
        accessor: "distance",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Cost",
        accessor: "final_amount",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Ride Duration",
        accessor: "total_hrs",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value, row }) => {
          return (
            <p
              className={
                value == 1
                  ? "text-warning"
                  : value == 3
                  ? "text-warning"
                  : value == 4
                  ? "text-warning"
                  : value == 5
                  ? "text-warning"
                  : value == 6
                  ? "text-warning"
                  : value == 7
                  ? "text-warning"
                  : value == 8
                  ? "text-warning"
                  : value == 9
                  ? "text-warning"
                  : value == 10
                  ? "text-success"
                  : value == 11
                  ? "text-warning"
                  : value == 12
                  ? "text-success"
                  : "text-danger"
              }
            >
              {value == 1
                ? "Progress"
                : value == 3
                ? "Progress"
                : value == 4
                ? "Progress"
                : value == 5
                ? "Progress"
                : value == 6
                ? "Progress"
                : value == 7
                ? "Progress"
                : value == 8
                ? "Progress"
                : value == 9
                ? "Progress"
                : value == 10
                ? "Completed"
                : value == 11
                ? "Progress"
                : value == 12
                ? "Completed"
                : "Rejected"}
            </p>
          );
        },
      },

      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value, row }) => {
          return (
            <React.Fragment>
              <button
                className="comn-btn bg-primary edit-btn-svg"
                onClick={() =>
                  navigate("/booking/view-booking", {
                    state: { id: row.original.id, index: row.index + 1 },
                  })
                }
              >
                <FontAwesomeIcon icon={faEye} />
              </button>
            </React.Fragment>
          );
        },
      },
    ],
    []
  );

  return (
    <aside>
      <TopHeader type="offline-booking" />
      <article className="p-3">
        <div className="p-2 bg-white rounded-2">
          <div className="ad-btn d-flex justify-content-end py-2">
            {permissons?.includes("CAN-ADD-OFFLINE BOOOKING") ? (
              <button
                onClick={() =>
                  navigate("/offline-booking/add-booking", {
                    state: {
                      type: "add",
                    },
                  })
                }
                className="comn-btn bg-primary1 px-3 py-2"
              >
                Add Offline Booking
              </button>
            ) : null}
          </div>
          <Tabs
            defaultActiveKey="Detail-View"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="Detail-View" title="Offline List">
              <div className="ad-btn w-100 flex-ae-jb px-0 py-2">
                <Col md={3} xs={6} className="px-2 my-2">
                  <span>From Date</span>
                  <input
                    type="date"
                    className="form-control"
                    onChange={startDate}
                    value={from}
                  />
                </Col>
                <Col md={3} xs={6} className="px-2 my-2">
                  <span>To Date</span>
                  <input
                    type="date"
                    className="form-control"
                    onChange={ToDate}
                    value={to}
                  />
                </Col>
              </div>

              <Table columns={columns} data={list} className="" />
            </Tab>
            <Tab eventKey="Detail" title="Offline Ride List">
              <div className="ad-btn w-100 flex-ae-jb px-0 py-2">
                <Col md={3} xs={6} className="px-2 my-2">
                  <span>From Date</span>
                  <input
                    type="date"
                    className="form-control"
                    onChange={startDates}
                    value={froms}
                  />
                </Col>
                <Col md={3} xs={6} className="px-2 my-2">
                  <span>To Date</span>
                  <input
                    type="date"
                    className="form-control"
                    onChange={ToDates}
                    value={tos}
                  />
                </Col>
              </div>
              <Table columns={columns1} data={lists} className="" />
            </Tab>
          </Tabs>
        </div>
      </article>
    </aside>
  );
};

export default OfflineBooking;
