import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import useToken from "../../Storage/useToken";
import { BASE_URL, URL } from "./constants";

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      headers.set(
        "Authorization",
        "Bearer " + JSON.parse(localStorage.getItem("token"))
      );
      //   headers.set("Content-Type", "application/json");
      // headers.set("Accept", "multipart/form-data");
      // headers.set("Content-Type", "multipart/form-data");
    },
  }),
  refetchOnMountOrArgChange: true,
  tagTypes: ["profileData", "menuList", "tableList"],
  endpoints: (builder) => ({
    // LOGIN
    login: builder.mutation({
      query: (payload) => ({
        url: URL.LOGIN,
        method: "POST",
        body: payload,
        headers: {},
      }),
    }),

    // LOGOUT
    logout: builder.query({
      query: () => ({
        url: URL.LOGOUT,
      }),
    }),

    // Change Password
    changePassword: builder.mutation({
      query: (payload) => ({
        url: URL.CHANGE_PASSWORD,
        method: "POST",
        body: payload,
        headers: {},
      }),
    }),

    // ROLE LIST
    roleList: builder.query({
      query: () => ({
        url: URL.ROLE,
      }),
    }),

    // ROLE ADD
    roleAdd: builder.mutation({
      query: (payload) => ({
        url: URL.ROLE,
        method: "POST",
        body: payload,
        headers: {},
      }),
    }),

    // ROLE EDIT
    roleEdit: builder.mutation({
      query: ({ id, payload }) => ({
        url: URL.ROLE + "/" + id,
        method: "POST",
        body: payload,
      }),
    }),

    // ROLE VIEW
    roleView: builder.query({
      query: (id) => ({
        url: URL.ROLE + "/" + id + "/edit",
      }),
    }),

    // ROLE MODULE
    roleModule: builder.query({
      query: () => ({
        url: URL.ROLE + "/create",
      }),
    }),

    // ROLE STATUS
    roleStatus: builder.query({
      query: (id) => ({
        url: URL.ROLE + "/status/" + id,
      }),
    }),

    // ROLE DELETE
    roleDelete: builder.mutation({
      query: (id) => ({
        url: URL.ROLE + "/" + id,
        method: "DELETE",
      }),
    }),

    // ADMIN LIST
    adminList: builder.query({
      query: () => ({
        url: URL.ADMIN,
      }),
    }),

    // ADMIN ADD
    adminAdd: builder.mutation({
      query: (payload) => ({
        url: URL.ADMIN,
        method: "POST",
        body: payload,
        headers: {},
      }),
    }),

    // ADMIN EDIT
    adminEdit: builder.mutation({
      query: ({ id, payload }) => ({
        url: URL.ADMIN + "/" + id,
        body: payload,
        method: "POST",
      }),
    }),

    // ADMIN VIEW
    adminView: builder.query({
      query: (id) => ({
        url: URL.ADMIN + "/" + id,
      }),
    }),

    // ADMIN STATUS
    adminStatus: builder.query({
      query: (id) => ({
        url: URL.ADMIN + "/status/" + id,
      }),
    }),

    // ADMIN ROLES
    adminRoles: builder.query({
      query: () => ({
        url: URL.ADMIN_ROLES,
      }),
    }),

    // ADMIN DELETE
    adminDelete: builder.mutation({
      query: ({ id, payload }) => ({
        url: URL.ADMIN + "/" + id,
        method: "POST",
        body: payload,
      }),
    }),

    // DRIVER REUEST
    driverRequest: builder.query({
      query: (param) => ({
        url: URL.DRIVER_REQUSET + param,
      }),
    }),

    // DRIVER VIEW REQ & REJE
    driverRequestView: builder.query({
      query: (id) => ({
        url: URL.DRIVER_REQUSET_REJECT + "/view/" + id,
      }),
    }),

    // DRIVER PROOF ACCEPT
    driverAccept: builder.mutation({
      query: (payload) => ({
        url: URL.PROOF_ACCEPT,
        method: "POST",
        body: payload,
      }),
    }),

    // DRIVER LIST
    driverList: builder.query({
      query: (param) => ({
        url: URL.DRIVERS + param,
      }),
    }),

    // DRIVER STATUS
    driverStatus: builder.query({
      query: (id) => ({
        url: URL.DRIVER_STATUS + id,
      }),
    }),

    // RIDE TYPE LIST
    rideTypeList: builder.query({
      query: () => URL.RIDE_TYPE,
    }),

    // RIDE TYPE ADD
    rideTypeAdd: builder.mutation({
      query: (payload) => ({
        url: URL.RIDE_TYPE,
        method: "POST",
        body: payload,
      }),
    }),

    // RIDE TYPE EDIT
    rideTypeEdit: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.RIDE_TYPE + "/update/" + id,
        method: "POST",
        body: payload,
      }),
    }),

    // RIDE TYPE VIEW
    rideTypeView: builder.query({
      query: (id) => URL.RIDE_TYPE + "/" + id,
    }),

    // RIDE TYPE DELETE
    rideTypeDelete: builder.mutation({
      query: (id) => ({
        url: URL.RIDE_TYPE + "/" + id,
        method: "DELETE",
      }),
    }),

    // RIDE TYPE STATUS
    rideTypeStatus: builder.query({
      query: (id) => "/status/" + id,
    }),

    // OTHER CHARGES ADD
    otherChargeAdd: builder.mutation({
      query: (payload) => ({
        url: URL.OTHERCHARGES,
        method: "POST",
        body: payload,
      }),
    }),

    // OTHER CHARGES EDIT
    otherChargeEdit: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.OTHERCHARGES + "/" + id,
        method: "POST",
        body: payload,
      }),
    }),

    // OTHER CHARGES LIST
    otherChargeList: builder.query({
      query: ( params ) => ({
        url: URL.OTHERCHARGES + params,
      }),
    }),

    // OTHER CHARGES VIEW
    otherChargeView: builder.query({
      query: (id) => URL.OTHERCHARGES + "/" + id,
    }),

    // OTHER CHARGES DELETE
    otherChargeDelete: builder.mutation({
      query: (id) => ({
        url: URL.OTHERCHARGES + "/" + id,
        method: "DELETE",
      }),
    }),

    // FARE LIST
    fareList: builder.query({
      query: (params) => URL.FARE + params,
    }),

    // FARE VIEW
    fareView: builder.query({
      query: (id) => URL.FARE + "/" + id,
    }),

    // FARE ADD
    fareAdd: builder.mutation({
      query: (payload) => ({
        url: URL.FARE,
        method: "POST",
        body: payload,
      }),
    }),

    // FARE EDIT
    fareEdit: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.FARE + "/" + id,
        method: "POST",
        body: payload,
      }),
    }),

    // FARE DELETE
    fareDelete: builder.mutation({
      query: (id) => ({
        url: URL.FARE + "/" + id,
        method: "DELETE",
      }),
    }),

    // PEAK LIST
    peakList: builder.query({
      query: (params) => URL.PEAK + params,
    }),

    // PEAK VIEW
    peakView: builder.query({
      query: (id) => URL.PEAK + "/" + id,
    }),

    // PEAK ADD
    peakAdd: builder.mutation({
      query: (payload) => ({
        url: URL.PEAK,
        method: "POST",
        body: payload,
      }),
    }),

    // PEAK EDIT
    peakEdit: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.PEAK + "/" + id,
        method: "POST",
        body: payload,
      }),
    }),

    // PEAK DELETE
    peakDelete: builder.mutation({
      query: (id) => ({
        url: URL.PEAK + "/" + id,
        method: "DELETE",
      }),
    }),

    //user
    userlist: builder.query({
      query: () => ({
        url: URL.USERS,
      }),
    }),

    //user filer
    userListFilter: builder.query({
      query: (params) => ({
        url: URL.USERS + params,
      }),
    }),

    // USER STATUS
    userStatus: builder.query({
      query: (id) => ({
        url: URL.USER_STATUS + id,
      }),
    }),

    //USER_VIEW
    userview: builder.query({
      query: (id) => ({
        url: URL.USERS_VIEW + id,
      }),
    }),

    // USER PUSH ADD
    pushNotifiAdd: builder.mutation({
      query: (payload) => ({
        url: URL.PUSH_NOTIFY,
        method: "POST",
        body: payload,
      }),
    }),
    //USER HISTORY
    getUserHistory: builder.query({
      query: (id) => ({
        url: URL.USER_RIDE_HISTORY + id,
      }),
    }),

    getUserHistoryFilter: builder.query({
      query: ({ id, params }) => ({
        url: URL.USER_RIDE_HISTORY + id + params,
      }),
    }),
    //bOOK
    bookingList: builder.query({
      query: () => ({
        url: URL.BOOKING_LIST,
      }),
    }),
    bookingListFilter: builder.query({
      query: (params) => ({
        url: URL.BOOKING_LIST + params,
      }),
    }),

    bookingView: builder.query({
      query: (id) => ({
        url: URL.BOOKING_VIEW + id,
      }),
    }),

    driverView: builder.query({
      query: (id) => ({
        url: URL.DRIVER_VIEW + id,
      }),
    }),

    driverSubscription: builder.mutation({
      query: (payload) => ({
        url: URL.DRIVER_SUBSCRIPTION_DATE,
        method: "POST",
        body: payload,
      }),
    }),
    //ridesFilter

    completedRides: builder.query({
      query: () => ({
        url: URL.COMPLETED_RIDES,
      }),
    }),
    completedRidesFilter: builder.query({
      query: (params) => ({
        url: URL.COMPLETED_RIDES + params,
      }),
    }),
    //Booking

    scheduledList: builder.query({
      query: () => ({
        url: URL.SCHEDULED_BOOKING,
      }),
    }),
    scheduledListFilter: builder.query({
      query: (params) => ({
        url: URL.SCHEDULED_BOOKING + params,
      }),
    }),
    // SUBSCRIPTION
    getSubscription: builder.query({
      query: () => ({
        url: URL.SUBSCRIPTION,
      }),
    }),
    getStatusSubcription: builder.query({
      query: (id) => ({
        url: URL.SUBSCRIPTION + "/status/" + id,
      }),
    }),

    editSubscription: builder.query({
      query: (id) => ({
        url: URL.SUBSCRIPTION + "/" + id,
      }),
    }),

    updateSubscription: builder.mutation({
      query: ({ id, payload }) => ({
        url: URL.SUBSCRIPTION + "/" + id,
        method: "POST",
        body: payload,
      }),
    }),
    deleteSubscription: builder.mutation({
      query: (id) => ({
        url: URL.SUBSCRIPTION + "/" + id,
        method: "DELETE",
      }),
    }),
    addSubscription: builder.mutation({
      query: (payload) => ({
        url: URL.SUBSCRIPTION,
        method: "POST",
        body: payload,
      }),
    }),
    subscriptionPayment: builder.mutation({
      query: (payload) => ({
        url: URL.SUBSCRIPTION_PAYMENT,
        method: "POST",
        body: payload,
      }),
    }),
    //paytax
    payTaxList: builder.query({
      query: () => ({
        url: URL.PAY_TAX_LIST,
      }),
    }),
    payTaxListFilter: builder.query({
      query: (params) => ({
        url: URL.PAY_TAX_LIST + params,
      }),
    }),
    //Zone
    getZone: builder.query({
      query: () => ({
        url: URL.ZONE,
      }),
    }),

    deleteZone: builder.mutation({
      query: (id) => ({
        url: URL.ZONE + "/" + id,
        method: "DELETE",
      }),
    }),
    importZone: builder.mutation({
      query: (payload) => ({
        url: URL.IMPORT_ZONE,
        method: "POST",
        body: payload,
      }),
    }),
    addZone: builder.mutation({
      query: (payload) => ({
        url: URL.ZONE,
        method: "POST",
        body: payload,
      }),
    }),
    editZone: builder.query({
      query: (id) => ({
        url: URL.ZONE + "/" + id,
      }),
    }),
    updateZone: builder.mutation({
      query: ({ id, payload }) => ({
        url: URL.ZONE + "/" + id,
        method: "POST",
        body: payload,
      }),
    }),
    getSetting: builder.query({
      query: () => ({
        url: URL.SETTING,
      }),
    }),

    addSetting: builder.mutation({
      query: (payload) => ({
        url: URL.SETTING,
        method: "POST",
        body: payload,
      }),
    }),
    viewSetting: builder.query({
      query: (id) => ({
        url: URL.SETTING + "/" + id,
      }),
    }),
    updateSetting: builder.mutation({
      query: ({ id, payload }) => ({
        url: URL.SETTING + "/" + id,
        method: "POST",
        body: payload,
      }),
    }),
    deleteSetting: builder.mutation({
      query: (id) => ({
        url: URL.SETTING + "/" + id,
        method: "DELETE",
      }),
    }),
    cancelRejectMissed: builder.query({
      query: () => ({
        url: URL.CANCEL_REJECT_MISSED,
      }),
    }),
    cancelRejectMissedFilter: builder.query({
      query: (params) => ({
        url: URL.CANCEL_REJECT_MISSED + params,
      }),
    }),
    cancelView: builder.query({
      query: (id) => ({
        url: URL.CANCEL_RIDE_VIEW + id,
      }),
    }),
    rejectView: builder.query({
      query: (id) => ({
        url: URL.REJECT_RIDE_VIEW + id,
      }),
    }),
    activityLogFilter: builder.query({
      query: (params) => ({
        url: URL.ACTIVITY_LOG + params,
      }),
    }),
    activityLogView: builder.query({
      query: (id) => ({
        url: URL.ACTIVITY_LOG + "/" + id,
      }),
    }),
    activityLogViewFilter: builder.query({
      query: ({ id, params }) => ({
        url: URL.ACTIVITY_LOG + "/" + id + params,
      }),
    }),
    activityLog: builder.query({
      query: () => ({
        url: URL.ACTIVITY_LOG,
      }),
    }),
    pushNotifiList: builder.query({
      query: () => ({
        url: URL.PUSH_NOTIFY,
      }),
    }),
    pushNotifiDelete: builder.mutation({
      query: (id) => ({
        url: URL.PUSH_NOTIFY + "/" + id,
        method: "DELETE",
      }),
    }),
    pushNotifiAdd: builder.mutation({
      query: (payload) => ({
        url: URL.PUSH_NOTIFY,
        method: "POST",
        body: payload,
      }),
    }),
    rideNotifiView: builder.query({
      query: (id) => ({
        url: URL.RIDE_NOTIFICATION_VIEW  + id,
      }),
    }),
    userReview: builder.query({
      query: () => ({
        url: URL.USER_REVIEW,
      }),
    }),
    driverReview: builder.query({
      query: () => ({
        url: URL.DRIVER_REVIEW,
      }),
    }),
    reports: builder.query({
      query: (params) => ({
        url: URL.REPORTS + params,
      }),
    }),
    suportPending: builder.query({
      query: () => ({
        url: URL.HELP_SUPPORT_PENDING,
      }),
    }),
    suportResolved: builder.query({
      query: () => ({
        url: URL.HELP_SUPPORT_RESOLVED,
      }),
    }),
    replySupport: builder.mutation({
      query: (payload) => ({
        url: URL.REPLY_SUPPORT,
        method: "POST",
        body: payload,
      }),
    }),
    notifyList: builder.query({
      query: () => ({
        url: URL.NOTIFY_LIST,
      }),
    }),
    changePassword: builder.mutation({
      query: ({ payload }) => ({
        url: URL.CHANGE_PASSWORD,
        method: "POST",
        body: payload,
      }),
    }),
    dashboard: builder.query({
      query: () => ({
        url: URL.DASHBOARD,
      }),
    }),
    dashboardPie: builder.query({
      query: () => ({
        url: URL.DASHBOARD_PIE,
      }),
    }),
    dashboardRevenue: builder.query({
      query: () => ({
        url: URL.DASHBOARD_REVENUE,
      }),
    }),
    dashboardRevenueFilter: builder.query({
      query: (params) => ({
        url: URL.DASHBOARD_REVENUE + params,
      }),
    }),
    dashboardPay: builder.query({
      query: () => ({
        url: URL.DASHBOARD_PAY_TAX,
      }),
    }),
    dashboardPayFilter: builder.query({
      query: (params) => ({
        url: URL.DASHBOARD_PAY_TAX + params,
      }),
    }),
    rideNotification: builder.query({
      query: () => ({
        url: URL.RIDE_NOTIFICATION,
      }),
    }),
    rideSearchView: builder.query({
      query: (id) => ({
        url: URL.RIDE_SEARCH_NOTIFICTION + id,
      }),
    }),
    rideHistory: builder.query({
      query: (id) => ({
        url: URL.DRIVER_RIDE_HISTORY +id,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useLazyLogoutQuery,
  useChangePasswordMutation,
  useAdminAddMutation,
  useAdminEditMutation,
  useLazyAdminListQuery,
  useLazyAdminViewQuery,
  useLazyAdminStatusQuery,
  useAdminDeleteMutation,
  useRoleAddMutation,
  useLazyRoleModuleQuery,
  useLazyRoleListQuery,
  useLazyRoleViewQuery,
  useRoleDeleteMutation,
  useLazyRoleStatusQuery,
  useRoleEditMutation,
  useLazyAdminRolesQuery,
  useLazyDriverRequestQuery,
  useLazyDriverRequestViewQuery,
  useDriverAcceptMutation,
  useLazyDriverListQuery,
  useLazyDriverStatusQuery,
  useRideTypeAddMutation,
  useRideTypeEditMutation,
  useRideTypeDeleteMutation,
  useLazyRideTypeListQuery,
  useLazyRideTypeViewQuery,
  useLazyRideTypeStatusQuery,
  useOtherChargeAddMutation,
  useOtherChargeEditMutation,
  useLazyOtherChargeListQuery,
  useLazyFareListQuery,
  useLazyFareViewQuery,
  useFareAddMutation,
  useFareEditMutation,
  useLazyPeakListQuery,
  useLazyPeakViewQuery,
  usePeakAddMutation,
  usePeakEditMutation,
  useLazyOtherChargeViewQuery,
  useOtherChargeDeleteMutation,
  useFareDeleteMutation,
  usePeakDeleteMutation,
  useLazyUserlistQuery,
  useLazyUserListFilterQuery,
  useLazyUserStatusQuery,
  usePushNotifiAddMutation,
  useLazyUserviewQuery,
  useLazyGetUserHistoryQuery,
  useLazyGetUserHistoryFilterQuery,
  useLazyBookingViewQuery,
  useLazyBookingListFilterQuery,
  useLazyBookingListQuery,
  useLazyDriverViewQuery,
  useDriverSubscriptionMutation,
  useLazyCompletedRidesFilterQuery,
  useLazyCompletedRidesQuery,
  useLazyScheduledListFilterQuery,
  useLazyScheduledListQuery,
  useLazyGetSubscriptionQuery,
  useLazyGetStatusSubcriptionQuery,
  useLazyEditSubscriptionQuery,
  useAddSubscriptionMutation,
  useUpdateSubscriptionMutation,
  useSubscriptionPaymentMutation,
  useLazyPayTaxListFilterQuery,
  useLazyPayTaxListQuery,
  useLazyGetZoneQuery,
  useDeleteZoneMutation,
  useImportZoneMutation,
  useAddZoneMutation,
  useLazyEditZoneQuery,
  useUpdateZoneMutation,
  useLazyGetSettingQuery,
  useAddSettingMutation,
  useLazyViewSettingQuery,
  useUpdateSettingMutation,
  useDeleteSettingMutation,
  useLazyCancelRejectMissedFilterQuery,
  useLazyCancelRejectMissedQuery,
  useLazyCancelViewQuery,
  useLazyRejectViewQuery,
  useLazyActivityLogFilterQuery,
  useLazyActivityLogQuery,
  useLazyActivityLogViewQuery,
  useLazyActivityLogViewFilterQuery,
  useLazyPushNotifiListQuery,
  // usePushNotifiAddMutation,
  usePushNotifiDeleteMutation,
  useLazyDriverReviewQuery,
  useLazyUserReviewQuery,
  useLazyReportsQuery,
  useLazySuportPendingQuery,
  useLazySuportResolvedQuery,
  useReplySupportMutation,
  useLazyNotifyListQuery,
  // useChangePasswordMutation,
  useLazyDashboardQuery,
  useLazyDashboardPieQuery,
  useLazyDashboardRevenueQuery,
  useLazyDashboardPayFilterQuery,
  useLazyDashboardPayQuery,
  useLazyDashboardRevenueFilterQuery,
  useLazyRideNotificationQuery,
  useDeleteSubscriptionMutation,
  useLazyRideNotifiViewQuery,
  useLazyRideSearchViewQuery,
  useLazyRideHistoryQuery,
} = api;
