import React from "react";
import { Form } from "react-bootstrap";
import { useState } from "react";
import TopHeader from "../../../component/TopHeader";
import { bannerList, maleAvatar } from "../../../assets/img";
import { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ReactPaginate from "react-paginate";
import moment from "moment/moment";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPenAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
// import Pagination from "react-bootstrap/Pagination";
import { Row, Col, Pagination, Button } from "react-bootstrap";
import BTable from "react-bootstrap/Table";

import { GlobalFilter } from "../Roles/GlobalFilter";

import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";

import swal from "sweetalert";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import Swal from "sweetalert2";

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    globalFilter,
    setGlobalFilter,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      {/* show */}
      <Row className="mb-3 table-show row">
        <Col className="d-flex align-items-center">
          <p>Show</p>

          <select
            className="form-control w-auto mx-2 p-2 py-1"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
          <p>entries</p>
        </Col>
        <Col className="d-flex justify-content-end">
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          {/* <Link to="/support/add-support">
            <Button style={{ background: 'rgb(95,188,95)' }} className="btn-sm btn-round has-ripple ml-2 border-0">
              <i className="feather icon-plus" /> Add Ticket
            </Button>
          </Link> */}
        </Col>
      </Row>
      {/* show */}
      <BTable size="sm" striped bordered hover responsive {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="feather icon-arrow-down text-muted float-right" />
                      ) : (
                        <span className="feather icon-arrow-up text-muted float-right" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
      {/* pagination */}
      <Row className="justify-content-between table-show my-2">
        <Col>
          <span className="d-flex align-items-center">
            <p className="px-1">Page</p>{" "}
            <strong>
              {" "}
              {pageIndex + 1} of {pageOptions.length}{" "}
            </strong>{" "}
            <p className="px-1">|</p>
            <p className="px-1">Go to page:</p>{" "}
            <input
              type="number"
              className="form-control ml-2  p-1 py-1 text-center"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "40px" }}
            />
          </span>
        </Col>
        <Col>
          <Pagination className="justify-content-end ">
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          </Pagination>
        </Col>
      </Row>
      {/*paginations  */}
    </>
  );
}

function AddCoupon() {
  const navigate = useNavigate();
  const location = useLocation();

  const Id = location?.state?.id;
  const type = location?.state?.type;
  const formet = location?.state?.formet;

  const [adminData, setadminData] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  // const [activeStatus, setActiveStatus] = useState(data.status);

  //  Pagenation

  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [counter, setCounter] = useState(0);
  const [last, setLast] = useState(10);
  const [itemOffset, setItemOffset] = useState(0);

  const [searcharr, setSearchArr] = useState("");

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % adminData?.length;
    setItemOffset(newOffset);

    let temp2 = event.selected;
    setCounter(temp2);

    let temp = event.selected + 1 + "0";
    setLast(temp);
  };

  // useEffect(() => {
  //   let temp = [...adminData];

  //   if (searcharr == "") {
  //     const endOffset = itemOffset + itemsPerPage;

  //     setCurrentItems(adminData?.slice(itemOffset, endOffset));
  //     setPageCount(Math.ceil(adminData?.length / itemsPerPage));
  //   } else {
  //     let datas = temp?.filter((val) =>
  //       val?.tittle?.toLowerCase().includes(searcharr.toLowerCase())
  //     );

  //     setCurrentItems(datas);
  //   }
  // }, [searcharr]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;

    setCurrentItems(adminData?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(adminData?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, adminData]);

  const setListItems = (e) => {
    const value = e.target.value;

    setItemsPerPage(parseInt(value));
  };

  //   console.log("locat", location);
  const [image, setImage] = useState("");

  const [imagePre, setImagePre] = useState("");

  //   const [txt, setTxt] = useState([{ description: "" }]);

  //   const addText = () => {
  //     let temp = [...txt];

  //     temp.push({ description: "" });
  //     setTxt(temp);
  //   };

  //   const removeTxt = (index) => {
  //     let temp = [...txt];

  //     temp.splice(index, 1);
  //     setTxt(temp);
  //   };

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <>{row.index + 1}</>;
        },
      },
      {
        Header: "Ride ID",
        accessor: "ride_id",
        Cell: ({ value, row }) => {
          return <>{"#" + value}</>;
        },
      },
      {
        Header: "Request Date",
        accessor: "created_at",
        Cell: ({ value, row }) => {
          return <span>{moment(value).format("DD/MM/YYYY")}</span>;
        },
      },
      {
        Header: "Issued Date",
        accessor: "updated_at",
        Cell: ({ value, row }) => {
          return <span>{moment(value).format("DD/MM/YYYY")}</span>;
        },
      },

      {
        Header: "Issued Amount",
        accessor: "reward_amount",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      // {
      //   Header: "Expiry Date",
      //   accessor: "end_date",
      //   Cell: ({ value, row }) => {
      //     return (
      //       <span>
      //         {value != null ? moment(value).format("DD/MM/YYYY") : " "}
      //       </span>
      //     );
      //   },
      // },
    ],
    []
  );

  const [list, setList] = useState([]);
  const [lists, setLists] = useState([]);
  const [total, setTotal] = useState([]);

  const getList = () => {
    let data = {
      user_id: Id,
    };
  };

  const submitHandle = () => {
    let datas = {
      user_id: Id,
    };
  };

  const historyRewards = () => {
    let data = {
      user_id: Id,
    };
  };

  useEffect(() => {
    if (Id) {
      getList();
      historyRewards();
    }
  }, []);

  return (
    <>
      <aside>
        {type === "edit" ? (
          <TopHeader type="editCoupen" />
        ) : (
          <TopHeader type="addCoupen" />
        )}

        <article>
          <div className="p-3">
            <div className="p-3 bg-white rounded-2">
              <div className="flex-ac-jb">
                <p
                  className="permission-text txt-primary4 mb-0 mt-0 text-uppercase"
                  for=""
                >
                  {list?.user?.name}
                </p>
                <img className="avatar-img" src={maleAvatar} />
              </div>
              <Form className="d-flex flex-wrap">
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Name
                      </label>
                      <input
                        id="example-if-email"
                        name="tittle"
                        placeholder="tittle"
                        className="bg-transparent"
                        type="text"
                        value={list?.user?.name}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        User ID
                      </label>
                      <input
                        id="example-if-email"
                        name="percentage"
                        placeholder="percentage"
                        className="bg-transparent"
                        type="text"
                        value={"#00" + list?.user_id}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Mobile Number
                      </label>
                      <input
                        placeholder="amount"
                        name="amount"
                        className="bg-transparent"
                        type="text"
                        value={list?.user?.mobile}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        UPI ID
                      </label>
                      <input
                        placeholder="validity"
                        name="validity"
                        className="bg-transparent"
                        type="text"
                        value={list?.user?.upi_id}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Total Rewards Offered (INR)
                      </label>
                      <input
                        type="text"
                        id="example-if-email"
                        placeholder="total_reward_offered"
                        name="code"
                        className="bg-transparent"
                        value={total?.total_reward_offered}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Total Rewards Expired (INR)
                      </label>
                      <input
                        type="text"
                        id="example-if-email"
                        placeholder="code"
                        name="code"
                        className="bg-transparent"
                        value={total?.total_reward_expired}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Rewards Withdrawn
                      </label>
                      <input
                        type="text"
                        id="example-if-email"
                        placeholder="reward_withdrawn"
                        name="code"
                        className="bg-transparent"
                        value={total?.reward_withdrawn}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Withdrawn Request
                      </label>
                      <input
                        type="text"
                        id="example-if-email"
                        placeholder="withdrawn_request"
                        name="code"
                        className="bg-transparent"
                        value={total?.withdrawn_request}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="form-group col-12 col-sm-6 col-lg-4 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Image
                      </label>
                      <input
                        type="file"
                        onChange={(e) => {
                          setImage(e.target.files[0]);
                          setImagePre(URL.createObjectURL(e.target.files[0]));
                        }}
                      />
                      {imagePre ? (
                        <div className="w-90 prev-Image">
                          <img className="w-100" src={imagePre} />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div> */}
                {/* {txt?.map((i, index) => ( */}
              </Form>
              {/* <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
                <button className="comn-btn bg-primary px-3 py-2 me-2">
                  Submit
                </button>
              </div> */}
            </div>
          </div>

          {formet == "rewardRequest" ? (
            <article>
              <div className="p-3">
                <div className="p-2 bg-white rounded-2">
                  <Form className="d-flex flex-wrap">
                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0 ">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Requested Date
                          </label>
                          <input
                            type="text"
                            id="example-if-email"
                            placeholder="code"
                            name="code"
                            className="bg-transparent"
                            value={
                              list?.created_at
                                ? moment(list?.created_at).format("DD/MM/YYYY")
                                : ""
                            }
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0 text-end">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <button
                            type="button"
                            className="comn-btn bg-primary1 px-3 py-2"
                            style={{ marginTop: "24px" }}
                            onClick={submitHandle}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </article>
          ) : null}

          <article>
            <div className="p-3">
              <div className="p-2 bg-white rounded-2">
                <div className="ad-btn w-100 flex-ac-jb p-2">
                  <h4>History</h4>
                  {/* <span>From Date</span>
                  <input type="date" />
                  <span>To Date</span>
                  <input type="date" />
                  <Form></Form>
                  <div className="flex-ac-jc">
                    <Form className="search-input me-2">
                      <input
                        onChange={(e) => setSearchArr(e.target.value)}
                        placeholder="search..."
                        type="search"
                        className="bg-transparent rounded-1 px-2 py-1"
                      />
                    </Form>
                  </div> */}
                </div>
                <Table columns={columns} data={lists} />
              </div>
            </div>
          </article>
        </article>
      </aside>
    </>
  );
}

export default AddCoupon;
