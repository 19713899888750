import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../../../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPenAlt, faEye } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { bannerList, maleAvatar } from "../../../assets/img";
import Pagination from "react-bootstrap/Pagination";
import { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import moment from "moment/moment";

const OfflineHistory = () => {
  const navigate = useNavigate();

  const [adminData, setadminData] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [counter, setCounter] = useState(0);
  const [last, setLast] = useState(10);
  const [itemOffset, setItemOffset] = useState(0);
  const [list, setList] = useState([]);
  const [lists, setLists] = useState([]);
  const [rideDReview, setRideDReview] = useState({});
  const [rideUReview, setRideUReview] = useState({});
  const [searcharr, setSearchArr] = useState("");

  useEffect(() => {
    let temp = [...adminData];

    if (searcharr == "") {
      const endOffset = itemOffset + itemsPerPage;

      setCurrentItems(adminData?.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(adminData?.length / itemsPerPage));
    } else {
      let datas = temp?.filter((val) =>
        val?.name?.toLowerCase().includes(searcharr.toLowerCase())
      );

      setCurrentItems(datas);
    }
  }, [searcharr]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;

    setCurrentItems(adminData?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(adminData?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, adminData]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % adminData?.length;
    setItemOffset(newOffset);

    let temp2 = event.selected;
    setCounter(temp2);

    let temp = event.selected + 1 + "0";
    setLast(temp);
  };

  const setListItems = (e) => {
    const value = e.target.value;

    setItemsPerPage(parseInt(value));
  };
  const location = useLocation();
  const Id = location?.state?.id;

  const data = location?.state?.data;

  // console.log(data);

  // const getList = () => {
  //   api.bookingView(Id).then((res) => {
  //     setList(res?.booking);
  //     const driverReview = res?.booking?.ride_review?.filter(
  //       (i) => i?.driver_rating !== null
  //     );
  //     const userReview = res?.booking?.ride_review?.filter(
  //       (i) => i?.user_rating !== null
  //     );
  //     if (driverReview) {
  //       setRideDReview(driverReview[0]);
  //     }
  //     if (userReview) {
  //       setRideUReview(userReview[0]);
  //     }
  //   });
  // };

  // useEffect(() => {
  //   if (Id) {
  //     getList();
  //   }
  // }, []);

  return (
    <aside>
      <TopHeader type="view-offline" />
      <article className="p-3">
        <article>
          <div className="p-3">
            <div className="p-3 bg-white rounded-2">
              <div className="flex-ac-jb">
                <p
                  className="permission-text txt-primary4 mb-0 mt-0 text-uppercase"
                  for=""
                >
                  {data?.user?.name}
                </p>
                {/* <img
                  className="avatar-img"
                  src={data?.driver?.img_url || maleAvatar}
                /> */}
                {/* <p
                  className="permission-text txt-primary4 mb-0 mt-0 text-uppercase"
                  for=""
                ></p> */}
                <img
                  className="avatar-img"
                  src={data?.user?.img_url || maleAvatar}
                />
              </div>
              <Form className="d-flex flex-wrap">
                {/* <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Driver Name
                      </label>
                      <input
                        id="example-if-email"
                        name="Phone_Number"
                        placeholder="Driver Name"
                        className="bg-transparent"
                        type="text"
                        value={list?.driver?.name}
                        disabled
                      />
                    </div>
                  </div>
                </div> */}
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        User Name
                      </label>
                      <input
                        id="example-if-email"
                        name="Phone_Number"
                        placeholder="User Name"
                        className="bg-transparent"
                        type="text"
                        value={data?.user?.name}
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Ride ID
                      </label>
                      <input
                        id="example-if-email"
                        name="Phone_Number"
                        placeholder="Ride Id"
                        className="bg-transparent"
                        type="text"
                        value={data?.id ? "#" + data?.id : "-"}
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Ride Type
                      </label>
                      <input
                        id="example-if-email"
                        name="Phone_Number"
                        placeholder="Type"
                        className="bg-transparent"
                        type="text"
                        value={
                          data?.ride_type == 1
                            ? "Ride Now"
                            : data?.ride_type == 2
                            ? "Pre Offine Booking"
                            : data?.ride_type == 3
                            ? "Offline Booking by Admin"
                            : ""
                        }
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Ride Status
                      </label>
                      <input
                        id="example-if-email"
                        name="Phone_Number"
                        placeholder="Status"
                        className="bg-transparent"
                        type="text"
                        value={
                          data.status == 0
                            ? "Searching"
                            : data.status == 1
                            ? "Driver Not Allocated"
                            : data.status == 2
                            ? "Ride Allocated"
                            : data.status == 3
                            ? "Cancelled"
                            : data.status == 4
                            ? "Pre Booking"
                            : " "
                        }
                        disabled
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        OTP
                      </label>
                      <input
                        type="text"
                        id="example-if-email"
                        placeholder="otp"
                        className="bg-transparent"
                        value={data?.otp}
                        disabled
                      />
                    </div>
                  </div>
                </div> */}
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Ride Cost
                      </label>
                      <input
                        type="text"
                        id="example-if-email"
                        placeholder="Cost"
                        className="bg-transparent"
                        value={data?.amount}
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Total hrs
                      </label>
                      <input
                        type="text"
                        id="example-if-email"
                        placeholder="Payment"
                        className="bg-transparent"
                        value={data?.total_hrs}
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Ride Date and Time Stamp
                      </label>
                      <input
                        type="text"
                        name="license-number"
                        id="example-if-email"
                        placeholder="Start Date"
                        className="bg-transparent"
                        value={
                          data?.created_at
                            ? moment(data?.created_at).format(
                                "DD/MM/YYYY, hh:mm A"
                              )
                            : moment(data?.created_at).format(
                                "DD/MM/YYYY, hh:mm A"
                              )
                        }
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Ride End Date and Time stamp
                      </label>
                      <input
                        placeholder="End Date"
                        className="bg-transparent"
                        type="text"
                        value={
                          data?.status == 3
                            ? "Progress"
                            : data?.status == 4
                            ? "Progress"
                            : data?.status == 5
                            ? "Progress"
                            : data?.status == 6
                            ? "Progress"
                            : data?.status == 7
                            ? "Progress"
                            : data?.status == 8
                            ? "Progress"
                            : data?.status == 9
                            ? "Progress"
                            : data?.status == 10
                            ? data?.updated_at
                              ? moment(data?.updated_at).format(
                                  "DD/MM/YYYY, hh:mm A"
                                )
                              : "-"
                            : data?.status == 11
                            ? "-"
                            : data?.status == 12
                            ? data?.updated_at
                              ? moment(data?.updated_at).format(
                                  "DD/MM/YYYY, hh:mm A"
                                )
                              : "-"
                            : data?.status == 1
                            ? "Progress"
                            : data?.status == 2
                            ? data?.updated_at
                              ? moment(data?.updated_at).format(
                                  "DD/MM/YYYY, hh:mm A"
                                )
                              : "-"
                            : data?.updated_at
                            ? moment(data?.updated_at).format(
                                "DD/MM/YYYY, hh:mm A"
                              )
                            : "-"
                        }
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-12  col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        From
                      </label>
                      {/* <input
                        type="email"
                        id="example-if-email"
                        placeholder="form"
                        className="bg-transparent"
                        value={list?.ride_detail?.pickup_address}
                        disabled
                      /> */}
                      <p className="address-request textarea-height" disabled>
                        {data?.pickup_address}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        To
                      </label>
                      {/* <input
                        placeholder="to"
                        type="text"
                        name="aadhaar number"
                        className="bg-transparent"
                        value={list?.ride_detail?.drop_address}
                        disabled
                      /> */}
                      <p className="address-request textarea-height" disabled>
                        {data?.drop_address}
                      </p>
                    </div>
                  </div>
                </div>

                {/* <div className="form-group col-12 col-sm-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        User Review
                      </label>
                      {rideUReview?.user_rating == 1 ? (
                        <p style={{ color: "#FBC740" }}>&#9733;</p>
                      ) : rideUReview?.user_rating == 2 ? (
                        <p style={{ color: "#FBC740" }}>&#9733;&#9733;</p>
                      ) : rideUReview?.user_rating == 3 ? (
                        <p style={{ color: "#FBC740" }}>
                          &#9733; &#9733; &#9733;
                        </p>
                      ) : rideUReview?.user_rating == 4 ? (
                        <p style={{ color: "#FBC740" }}>
                          &#9733; &#9733; &#9733; &#9733;
                        </p>
                      ) : rideUReview?.user_rating == 5 ? (
                        <p style={{ color: "#FBC740" }}>
                          &#9733; &#9733; &#9733; &#9733; &#9733;
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div> */}
                {/* <div className="form-group col-12 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        User Feedback
                      </label>
                      <p className="address-request textarea-height" disabled>
                        {rideUReview?.user_review}
                      </p>
                    </div>
                  </div>
                </div> */}
                {/* <div className="form-group col-12 col-sm-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Driver Review
                      </label>
                      {rideDReview?.driver_rating == 1 ? (
                        <p style={{ color: "#FBC740" }}>&#9733;</p>
                      ) : rideDReview?.driver_rating == 2 ? (
                        <p style={{ color: "#FBC740" }}>&#9733;&#9733;</p>
                      ) : rideDReview?.driver_rating == 3 ? (
                        <p style={{ color: "#FBC740" }}>
                          &#9733; &#9733; &#9733;
                        </p>
                      ) : rideDReview?.driver_rating == 4 ? (
                        <p style={{ color: "#FBC740" }}>
                          &#9733; &#9733; &#9733; &#9733;
                        </p>
                      ) : rideDReview?.driver_rating == 5 ? (
                        <p style={{ color: "#FBC740" }}>
                          &#9733; &#9733; &#9733; &#9733; &#9733;
                        </p>
                      ) : (
                        ""
                      )}
                      {/* {} 
                    </div>
                  </div>
                </div> 
                <div className="form-group col-12 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Driver Feedback
                      </label>
                      <p className="address-request textarea-height" disabled>
                        {rideDReview?.driver_review}
                      </p>
                    </div>
                  </div>
                </div>*/}
              </Form>
            </div>
          </div>
        </article>
      </article>
    </aside>
  );
};

export default OfflineHistory;
