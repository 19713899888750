import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../../../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenAlt } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { toast } from "react-toastify";
import {
  useLazyRoleModuleQuery,
  useLazyRoleViewQuery,
  useRoleAddMutation,
  useRoleEditMutation,
} from "../../../constants/redux/api/api";

const AddRoles = () => {
  const navigate = useNavigate();

  // RTK QUERY
  const [roleModuleApi] = useLazyRoleModuleQuery();
  const [roleAddApi] = useRoleAddMutation();
  const [roleEditApi] = useRoleEditMutation();
  const [roleView] = useLazyRoleViewQuery();

  const [adminData, setadminData] = useState();
  const [name, setName] = useState("");
  const [editModal, setEditModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [btn, setBtn] = useState(false);

  const [permissions, setPermissions] = useState([]);
  const location = useLocation();
  const Id = location?.state?.id;
  const type = location?.state?.type;

  useEffect(() => {
    roleModuleApi()
      .unwrap()
      .then((res) => {
        setadminData(res?.modules);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  const selectRole = (id) => {
    let temp = [...permissions];
    const value = id;

    const index = temp.indexOf(id);

    if (temp.includes(value)) {
      temp.splice(index, 1);
      setPermissions(temp);
    } else {
      temp.push(value);
      setPermissions(temp);
    }
    console.log(temp);
  };

  const addRole = () => {
    let data = {
      role: name,
      permissions: permissions,
    };

    if (Id) {
      data._method = "PUT";
    }
    if (name && permissions.length >= 1) {
      if (Id) {
        setBtn(true);
        roleEditApi({ id: Id, payload: data })
          .unwrap()
          .then((res) => {
            if (res?.status == "success") {
              setBtn(false);
              toast.success(res?.message, {
                position: "top-right",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoClose: 2000,
              });
              navigate("/role");
            } else {
              setBtn(false);
            }
          });
      } else {
        setBtn(true);
        roleAddApi(data)
          .unwrap()
          .then((res) => {
            if (res?.status == "success") {
              setBtn(false);
              toast.success(res?.message, {
                position: "top-right",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoClose: 2000,
              });
              navigate("/role");
            } else {
              setBtn(false);
            }

            // console.log(res);
            // <Navigate to="/roles" replace={true} state={{ from: location }} />;
          });
      }
    } else if (!name) {
      toast("Please Enter Role Name", {
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        autoClose: 2000,
      });
    } else if (permissions.length == 0) {
      toast("Please Select  Permission", {
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        autoClose: 2000,
      });
    }
  };

  const viewRole = () => {
    roleView(Id)
      .unwrap()
      .then((res) => {
        let temp = [];
        res?.permissions?.map((list) => {
          temp.push(list);
        });
        setName(res?.role?.role);
        setPermissions(temp);

        // console.log(res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (Id) {
      viewRole();
    }
  }, []);

  return (
    <aside>
      {type == "view" ? (
        <TopHeader type="view-role" />
      ) : type == "edit" ? (
        <TopHeader type="edit-role" />
      ) : (
        <TopHeader type="add-role" />
      )}
      <article className="p-3">
        {/* <Toaster position="top-right" reverseOrder={false} /> */}

        <div className="col-sm-6 modal-label-input">
          <label className="text-primary-dark" for="">
            Role Name
          </label>
          <input
            onChange={(e) => setName(e.target.value)}
            type="text"
            id="example-if-email"
            name="example-if-email"
            placeholder="Role Name"
            disabled={type == "view" ? true : false}
            value={name}
            className="bg-transparent"
          />
        </div>
        <p className="permission-text txt-primary4 mb-0 mt-0" for="">
          Permissions
        </p>
        <div className="roles-page mt-0 d-flex flex-wrap">
          {adminData?.map((list, index) => {
            return (
              <div
                key={list.id}
                className="form-group col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xxl-2 pl-0"
              >
                <h6>{list?.code}</h6>
                {list?.permissions?.map((item, index) => {
                  return (
                    <div key={item.id} className="form-check ps-0">
                      <label className="form-checks-label">
                        <input
                          checked={permissions?.includes(item.id)}
                          onClick={() => selectRole(item.id)}
                          className="form-checks-input me-2"
                          type="checkbox"
                          disabled={type == "view" ? true : false}
                        />
                        {item.code}
                      </label>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
          {/* <button className="comn-btn bg-hash2 px-3 py-2 me-2">
                Cancel
              </button> */}

          {permissions.length >= 1 && (
            <button
              onClick={addRole}
              type="button"
              className="comn-btn bg-primary px-3 py-2 me-2"
              data-dismiss="modal"
              disabled={type == "view" ? true : btn ? true : false}
            >
              Submit
            </button>
          )}
        </div>
      </article>
    </aside>
  );
};

export default AddRoles;
